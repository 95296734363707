import { UNPROTECTED_ROUTES } from "../routes/routes";
import { removeAuthentication } from "../configs/userSlice";
import { freeCache } from "../configs/cache";
import { toast } from "sonner";

const userLogout = (navigate, dispatch, showToast = false) => {
  dispatch(removeAuthentication());
  dispatch(freeCache());
  if (showToast) {
    toast("You are logged on another device. Please try to login again");
  }
  sessionStorage.removeItem("authenticated");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("userDetail");
  sessionStorage.removeItem("userProfilePic");
  sessionStorage.clear();
  navigate(UNPROTECTED_ROUTES?.home);
};

export default userLogout;
