import React, { useState, useEffect, useRef, useCallback } from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import UserInput from "../Classroom/UserInput";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import UserComment from "./UserComment";

const BOX_LIMIT = 50;
const POLLING_TIME = 1000;

export default function LiveChat({ id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [liveChatData, setLiveChatData] = useState([]);
  const [commentText, setCommentText] = useState("");

  const scrollAreaRef = useRef(null);
  const isScrolledToBottom = useRef(true);

  const fetchLiveChat = useCallback(async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_RANK_PLUS_FETCH_LIVE_CHAT}lecture_id=${id}&comment_id=0`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchLiveChat(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();

      const comments = json?.data?.lectures;
      let renderComments = comments?.length > BOX_LIMIT ? comments.slice(-BOX_LIMIT) : comments;
      
      setLiveChatData(renderComments);
      sessionStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchDoubts", err);
    }
  }, [id, navigate, dispatch]);

  useEffect(() => {
    fetchLiveChat();
    const intervalId = setInterval(fetchLiveChat, POLLING_TIME);
    return () => clearInterval(intervalId);
  }, [fetchLiveChat]);

  const submitComment = async (retries = 6) => {
    try {
      const formData = new FormData();
      formData.append("lecture_id", id);
      formData.append("comment", commentText);

      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        body: formData,
      };

      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SUBMIT_COMMENT,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => submitComment(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      await response.json();
      setCommentText("");
      isScrolledToBottom.current = true;
      // console.log("Comment submitted, scrolling to bottom");
      scrollToBottom();
    } catch (err) {
      console.error("Error submitComment", err);
    }
  };

  const scrollToBottom = useCallback(() => {
    if (scrollAreaRef.current) {
      const scrollElement = scrollAreaRef.current.querySelector('[data-radix-scroll-area-viewport]');
      if (scrollElement) {
        scrollElement.scrollTop = scrollElement.scrollHeight;
        // console.log("Scrolled to bottom", scrollElement.scrollTop, scrollElement.scrollHeight);
      } else {
        console.warn("Scroll element not found");
      }
    } else {
      console.warn("scrollAreaRef not available");
    }
  }, []);

  useEffect(() => {
    if (isScrolledToBottom.current) {
      // console.log("New messages, scrolling to bottom");
      scrollToBottom();
    }
  }, [liveChatData, scrollToBottom]);

  const handleScroll = useCallback((event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const newIsScrolledToBottom = scrollTop + clientHeight >= scrollHeight - 10;
    if (newIsScrolledToBottom !== isScrolledToBottom.current) {
      // console.log("Scroll position changed:", newIsScrolledToBottom ? "at bottom" : "not at bottom");
      isScrolledToBottom.current = newIsScrolledToBottom;
    }
  }, []);

  return (
    <div style={{ fontFamily: "DM Sans" }}>
      <div className="text-2xl mb-3">Live Chat</div>
      <div>
        <ScrollArea 
          className="md:w-[25rem] md:h-[27rem] w-[24.5rem] h-[30rem] bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-2 mt-2"
          onScrollCapture={handleScroll}
          ref={scrollAreaRef}
        >
          <div className="flex flex-col">
            {liveChatData &&
              liveChatData.length > 0 &&
              liveChatData.map((ele, i) => (
                <UserComment key={i} {...ele} />
              ))}
          </div>
        </ScrollArea>
      </div>
      <div className="mt-2">
        <UserInput
          setFile={null}
          placeholder={"Type"}
          btnText={"Send"}
          allowFileUpload={false}
          setDoubtText={setCommentText}
          doubtText={commentText}
          callback={submitComment}
          inputWidth={"md:w-[14rem] w-[17rem]"}
        />
      </div>
    </div>
  );
}