import React from "react";
import LIBRARY_ICON from "../../../assets/LIBRARY_ICON.webp";
import { UNPROTECTED_ROUTES } from "../../../routes/routes";
import { Link, useLocation } from "react-router-dom";
import { NAVBAR_FREE_LIBRARY_DROPDOWN } from "../../../constants/text_constants";
import { Dot } from "lucide-react";
import LIBRARY_ICON_FILLED from "../../../assets/LIBRARY_ICON_FILLED.webp";

const Library = () => {
  const location = useLocation();
  return (
    <div>
      {/* Title Heading */}
      <div>
        <Link to={UNPROTECTED_ROUTES?.freeLibrary}>
          {location.pathname === UNPROTECTED_ROUTES?.freeLibrary ||
          location.pathname === UNPROTECTED_ROUTES?.freeLibraryNotes ||
          location.pathname === UNPROTECTED_ROUTES?.freeLibraryTests ||
          location.pathname === UNPROTECTED_ROUTES?.freeLibraryVideos ||
          location.pathname === UNPROTECTED_ROUTES?.blogs || 
          location.pathname.includes(UNPROTECTED_ROUTES?.freeLibraryVideosPlay) ? (
            <div className="flex items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2 rounded-xl">
              <img
                src={LIBRARY_ICON_FILLED}
                alt="LIBRARY_ICON"
                className="w-[1.6rem]"
              />
              <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold ml-1">
                Library
              </p>
            </div>
          ) : (
            <div className="flex items-center gap-1 p-2">
              <img
                src={LIBRARY_ICON}
                alt="LIBRARY_ICON"
                className="w-[1.6rem]"
              />
              <p className="text-lg underline underline-offset-4 bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] ml-1">
                Library
              </p>
            </div>
          )}
        </Link>
      </div>

      {/* Elements */}
      <div className="my-2 flex flex-col">
        {NAVBAR_FREE_LIBRARY_DROPDOWN.map((ele, i) => (
          <Link to={ele?.link}>
            <div className="flex items-center ml-[0.4rem]">
              <Dot />
              <div className="hover:text-[#1ECBDB] bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                {ele?.title}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Library;
