import React from "react";
import RankPlusLogo from "../../assets/RANK_PLUS_LOGO.webp";
import { Link } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { toggleSideBar } from "../../configs/sidebarSlice";
import MENU_ICON from "../../assets/MENU_ICON.webp";
import MENU_ICON_FILLED from "../../assets/MENU_ICON_FILLED.webp";

const NavbarLeft = () => {
  const dispatch = useDispatch();
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  function handleClick() {
    dispatch(toggleSideBar());
  }

  return (
    <div className="flex items-center">
      <div className="lg:block md:hidden hidden cursor-pointer absolute left-[1.5rem]">
        {isSideBarOpen ? (
          <img
            src={MENU_ICON_FILLED}
            alt="menu-icon"
            className="w-[2rem]"
            onClick={() => handleClick()}
          />
        ) : (
          <img
            src={MENU_ICON}
            alt="menu-icon"
            className="w-[2rem]"
            onClick={() => handleClick()}
          />
        )}
      </div>
      <Link to={UNPROTECTED_ROUTES?.home}>
        <div className="flex items-center ml-[2.5rem] cursor-pointer">
          <img
            alt="rank-plus-logo"
            src={RankPlusLogo}
            className="lg:w-[11rem] lg:h-[4rem] md:w-[8rem] md:h-[52px] h-[3rem] w-[8rem]"
            loading="lazy"
          />
        </div>
      </Link>
      {/* <div className = "lg:block md:hidden hidden">
        <SearchBar />
      </div> */}
    </div>
  );
};

export default NavbarLeft;
