import { createSlice } from "@reduxjs/toolkit";

const cacheSlice = createSlice({
  name: "cache",
  initialState: {
    stateCache: {},
    citiesCache: {},
    allCoursesCache: {},
    myCoursesCache: {},
    allTestsCache: {},
    myTestsCache: {},
    allBooksCache: {},
    myBooksCache: {},
    freeLibraryVideosCache: {},
    freeLibraryNotesCache: {},
    freeLibraryTestsCache: {},
    feedbackCache : {}
  },
  reducers: {
    setStateCache: (state, action) => {
      state.stateCache[action.payload[1]] = action.payload[0];
    },
    setCitiesCache: (state, action) => {
      state.citiesCache[action.payload[1]] = action.payload[0];
    },
    setAllCoursesCache: (state, action) => {
      state.allCoursesCache[action.payload[1]] = action.payload[0];
    },
    setMyCoursesCache: (state, action) => {
      state.myCoursesCache[action.payload[1]] = action.payload[0];
    },
    setAllTestsCache: (state, action) => {
      state.allTestsCache[action.payload[1]] = action.payload[0];
    },
    setMyTestsCache: (state, action) => {
      state.myTestsCache[action.payload[1]] = action.payload[0];
    },
    setAllBooksCache: (state, action) => {
      state.allBooksCache[action.payload[1]] = action.payload[0];
    },
    setMyBooksCache: (state, action) => {
      state.myBooksCache[action.payload[1]] = action.payload[0];
    },
    setFreeLibraryVideosCache: (state, action) => {
      state.freeLibraryVideosCache[action.payload[1]] = action.payload[0];
    },
    setFreeLibraryNotesCache: (state, action) => {
      state.freeLibraryNotesCache[action.payload[1]] = action.payload[0];
    },
    setFreeLibraryTestsCache: (state, action) => {
      state.freeLibraryTestsCache[action.payload[1]] = action.payload[0];
    },
    setFeedbackCache : (state, action) => {
      state.feedbackCache[action.payload[1]] = action.payload[0];
    },
    freeCache: (state, action) => {
      state.allBooksCache = {};
      state.myBooksCache = {};
      state.allTestsCache = {};
      state.myTestsCache = {};
      state.allCoursesCache = {};
      state.myCoursesCache = {};
    },
  },
});

export const {
  setStateCache,
  setCitiesCache,
  setAllCoursesCache,
  setAllTestsCache,
  setAllBooksCache,
  setFreeLibraryVideosCache,
  setFreeLibraryNotesCache,
  setFreeLibraryTestsCache,
  setMyCoursesCache,
  setMyTestsCache,
  setMyBooksCache,
  freeCache,
  setFeedbackCache
} = cacheSlice.actions;
export default cacheSlice.reducer;
