import React, { useState, useEffect } from "react";
import {
  COURSE_DETAILS_LIVE_CLASSES_HEADING,
  LOCKED_TOAST_TEXT,
  VIDEO_TYPE_YOUTUBE_LIVE,
  PAGE_LIMIT,
  LIVE_CLASS_NOT_STARTED_TOAST_TEXT,
} from "../../constants/text_constants";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { formatDate } from "../../utils/util-functions/formatDate";
import CALENDAR_ICON from "../../assets/CalendarBlank.png";
import { convertTo12HourFormat } from "../../utils/util-functions/formatTime";
import PLAY_ICON from "../../assets/Play.png";
import LOCK_ICON from "../../assets/Lock.png";
import LOCK_OPEN_ICON from "../../assets/LockSimpleOpen.png";
import { toast } from "sonner";
import CountdownTimer from "../Countdown/Countdown";
import CLOCK_ICON from "../../assets/Timer.png";
import { hasDateTimePassed } from "../../utils/hasDateTimePassed";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { useNavigate } from "react-router-dom";
import { PROTECTED_ROUTES } from "../../routes/routes";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import handleBuy from "../Buy/handleBuy";
import { ITEM_COURSE } from "../../constants/text_constants";

const LiveClassesTab = ({ courseId, isPurchased, data }) => {
  const [liveClassData, setLiveClassData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchCourseLiveClasses = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASSES +
          courseId +
          "&limit=10&page=" +
          currentPage,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseLiveClasses(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      setLiveClassData(json?.data);
    } catch (err) {
      console.error("Error", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      fetchCourseLiveClasses();
    } else {
      return;
    }
  }, [courseId, currentPage]);

  function handleClick(video_type, premier_datetime, id) {
    if (isPurchased === "0") {
      setIsModalOpen(true);
    } else {
      if (liveClassData?.is_locked) {
        setIsModalOpen(true);
      }
      if (!liveClassData?.is_locked) {
        const hasDateTimePassedResponse = hasDateTimePassed(premier_datetime);
        if (!hasDateTimePassedResponse) {
          toast(LIVE_CLASS_NOT_STARTED_TOAST_TEXT);
        }
        if (hasDateTimePassedResponse) {
          if (video_type === VIDEO_TYPE_YOUTUBE_LIVE) {
            // navigate to custom live player -> /live
            navigate(PROTECTED_ROUTES?.live + `/${id}`);
          } else {
            // navigate to custom player -> /classroom
            console.log("Normal Player");
          }
        }
      }
    }
  }

  if (!userAuthenticated) {
    return (
      <div>
        <div className="text-2xl font-semibold my-6">
          {COURSE_DETAILS_LIVE_CLASSES_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Classroom"} />
        </div>
      </div>
    );
  }

  if (liveClassData?.total === 0) {
    return (
      <div>
        <InValidLottie text={"No Live Classes as of now !"} />
      </div>
    );
  }

  return (
    <div style={{ fontFamily: "DM Sans" }}>
      <div className="lg:text-2xl text-lg font-semibold my-6">
        {COURSE_DETAILS_LIVE_CLASSES_HEADING}
      </div>
      {!userAuthenticated && (
        <div>
          <InValidLottie text={"You Need to Login to view Live Classes"} />
        </div>
      )}
      {!liveClassData && <div>.</div>}
      <div className="grid grid-rows-1 gap-5">
        {liveClassData?.lectures.map((ele, i) => (
          <div
            className="bg-[#F8FCFD] rounded-2xl lg:pt-4 lg:pb-4 pt-4 pb-9 px-8 flex lg:flex-row flex-col items-center justify-between lg:gap-y-0 gap-y-1 border border-[#DFE3EC] relative shadow-lg cursor-pointer"
            key={i}
            onClick={() =>
              handleClick(ele?.video_type, ele?.premier_datetime, ele?.id)
            }
          >
            {(liveClassData?.is_locked ||
              !hasDateTimePassed(ele?.premier_datetime)) && (
              <div className="absolute -top-2 -left-2 bg-[#DFE3EC] p-2 rounded-full">
                <img src={LOCK_ICON} alt="lock-icon" loading="lazy" />
              </div>
            )}
            {!liveClassData?.is_locked &&
              hasDateTimePassed(ele?.premier_datetime) && (
                <div className="absolute -top-2 -left-2 bg-[#03B3C3] p-2 rounded-full">
                  <img
                    src={LOCK_OPEN_ICON}
                    alt="lock-open-icon"
                    loading="lazy"
                  />
                </div>
              )}
            {/* left section */}
            <div className="flex gap-5">
              {/* Class Thumbnail */}
              <div>
                <img
                  src={ele?.thumbnail}
                  alt={ele?.title}
                  className="w-[10rem] h-[5rem] shadow-lg rounded-lg"
                  loading="lazy"
                />
              </div>
              {/* Class Title and Description */}
              <div>
                <div className="font-bold">{ele?.title}</div>
                <div>{stripHtml(ele?.description)}</div>
              </div>
            </div>
            {/* right Section */}
            <div className="flex gap-4 items-center">
              {/* Timing */}
              <div className="border-l md:border-[#DFE3EC] border-[#F8FCFD] pl-5 h-20">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      src={CALENDAR_ICON}
                      alt="blank-calendar"
                      loading="lazy"
                    />
                  </div>
                  <div className="text-[#82808D]">
                    Lecture Start Date and Time
                  </div>
                </div>
                <div className="flex gap-2 ml-6">
                  <div>{formatDate(ele?.premier_datetime)}</div>
                  <div>-</div>
                  <div>{convertTo12HourFormat(ele?.premier_datetime)}</div>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <div>
                    <img src={CLOCK_ICON} alt="clock-icon" loading="lazy" />
                  </div>
                  <div className="text-[#82808D]">Timer : </div>
                  <div>
                    <CountdownTimer targetDate={ele?.premier_datetime} />
                  </div>
                </div>
              </div>
              {/* Play Btn */}
              <div>
                <button
                  className="bg-[#5A277C] p-2 rounded-full"
                  onClick={() =>
                    handleClick(ele?.video_type, ele?.premier_datetime, ele?.id)
                  }
                >
                  <img src={PLAY_ICON} alt="play-icon" loading="lazy" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-[4rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="lg:max-w-[25rem] md:max-w-[20rem] max-w-[15rem] lg:h-[22vh] md:h-[40vh] h-[140vw]">
          <DialogHeader>
            <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
              <div className="text-2xl text-[#5A277C] font-semibold">
                Buy Course
              </div>
            </DialogTitle>
            <DialogDescription>
              <div className="my-2">
                To access this course. Please buy this course 😊
              </div>
            </DialogDescription>
            <DialogFooter className="sm:justify-center">
              <Button
                type="button"
                className="bg-[#5A277C] hover:bg-purple-700 mr-8"
                onClick={() =>
                  handleBuy(
                    dispatch,
                    navigate,
                    data?.id,
                    ITEM_COURSE,
                    data?.name,
                    data?.offer_price,
                    data?.offer_discount,
                    data?.price,
                    data?.principal_amount,
                    data?.course_banner,
                    data?.course_icon
                  )
                }
              >
                Buy Now
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LiveClassesTab;
