import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { useSelector, useDispatch } from "react-redux";
import { HandCoins } from "lucide-react";
import { PAGE_LIMIT } from "../../constants/text_constants";
import OfferCard from "../Card-Component/OfferCard";
import Lottie from "lottie-react";
import AnimationData from "../../assets/LookingLottie.json";
import Pagination from "../Pagination/Pagination";
import { ScrollArea } from "../../components/ui/scroll-area";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";

const ViewMoreOfferBtn = () => {
  const cart = useSelector((store) => store.cart.cart);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [offersData, setOffersData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchOffers = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_ITEM_OFFERS +
          `type=${cart?.item}&limit=10&page=${currentPage}&id=${cart?.id}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchOffers(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setOffersData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      sessionStorage.setItem("token", json?.data?.token);
    } catch (err) {
      console.error("Error fetchOffers", err);
    }
  };

  useEffect(() => {
    fetchOffers();
  }, [currentPage]);

  return (
    <div>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            className="border-2 border-[#5A277C] px-2 py-1 rounded-lg text-[#5A277C] hover:bg-[#5A277C] hover:text-white transition-all mr-2"
          >
            View All offers
          </Button>
        </DialogTrigger>
        <DialogContent className="md:max-w-[60rem] max-w-[23rem]">
          <DialogHeader>
            <DialogTitle>
              <div className="flex gap-2 items-center text-4xl">
                <div>
                  <HandCoins />
                </div>
                <div className = "md:text-2xl text-xl">All Offers</div>
              </div>
            </DialogTitle>
            <DialogDescription>
              {offersData?.offers?.length > 0 && (
                <ScrollArea className="w-[58rem] h-[30rem] mt-5">
                  <div className="grid md:grid-cols-2 grid-cols-1 gap-2 place-content-center">
                    {offersData?.offers?.map((ele, i) => (
                      <OfferCard
                        {...ele}
                        width={"w-[27rem]"}
                        description_limit={100}
                        key = {i}
                      />
                    ))}
                  </div>
                </ScrollArea>
              )}
              {offersData?.offers?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPages={totalPages}
                />
              )}

              {(!offersData || offersData?.total === 0) && (
                <div className="flex justify-center items-center">
                  <div className="md:w-[25rem] w-[20rem]">
                    <Lottie animationData={AnimationData} />
                    <div className="text-center text-[#5A277C] font-semibold text-xl">
                      No offers as of now
                    </div>
                  </div>
                </div>
              )}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter></DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewMoreOfferBtn;
