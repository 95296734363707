import React from "react";
import SectionHeading from "./SectionHeading";
import { LANDING_PAGE_FAQs } from "../../constants/text_constants";
import { LP_FAQ } from "../../constants/LP_FAQ";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Link } from "react-router-dom";
import { MoveRight } from "lucide-react";

const LPFAQs = () => {
  return (
    <div className="md:mt-[4rem] mt-[2rem] md:mb-[3rem] mb-[0rem] flex flex-col items-center">
      <SectionHeading
        heading={LANDING_PAGE_FAQs?.heading}
        headingColor={"#130C40"}
        subHeading={LANDING_PAGE_FAQs?.subHeading}
        width={"lg:w-[70rem] md:w-[30rem] w-[23rem]"}
      />
      <div className="md:w-[90%] w-[20rem] mt-[2rem] grid grid-cols-2 gap-x-[5rem]">
        {LP_FAQ.map((ele, i) => (
          <div key={i}>
            <Accordion type="single" collapsible>
              <AccordionItem value="item-1">
                <AccordionTrigger className="md:text-[1rem] text-sm text-left">
                  {ele?.ques}
                </AccordionTrigger>
                <AccordionContent className="md:text-[1rem] text-sm text-left">
                  <div className="flex gap-1">
                    <div>
                      <MoveRight />
                    </div>
                    <div>
                      {ele?.ans}{" "}
                      <span className="text-blue-600 font-semibold underline underline-offset-4">
                        {ele?.hasLink && (
                          <Link to={ele?.link} target="_blank">
                            link
                          </Link>
                        )}
                      </span>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LPFAQs;
