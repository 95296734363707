import React from "react";
import HOME_ICON_FILLED from "../../../assets/HOME_BUTTON_ICON_FILLED.webp";
import HOME_ICON from "../../../assets/HOME_BUTTON_ICON.webp";
import { UNPROTECTED_ROUTES } from "../../../routes/routes";
import { Link, useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();

  return (
    <div>
      <Link to={UNPROTECTED_ROUTES?.home}>
        {location.pathname === UNPROTECTED_ROUTES?.home ? (
          <div className="flex items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2 rounded-xl">
            <img src={HOME_ICON} alt="BOOKS_ICON" className="w-[1.9rem]" />
            <p className="text-lg bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] ml-1 font-bold">
              Home
            </p>
          </div>
        ) : (
          <div className="flex items-center gap-1 p-2">
            <img
              src={HOME_ICON_FILLED}
              alt="BOOKS_ICON"
              className="w-[1.9rem]"
            />
            <p className="text-lg bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] ml-1">
              Home
            </p>
          </div>
        )}
      </Link>
    </div>
  );
};

export default Home;
