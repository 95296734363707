import React from "react";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import StarRatings from "react-star-ratings";
import CALENDAR_ICON from "../../assets/Calendar.png";
import CALENDAR_BLANK_ICON from "../../assets/CalendarBlank.png";
import BOOK_ICON from "../../assets/BookOpenText.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { Link } from "react-router-dom";
import { Card, CardContent } from "../../components/ui/card";
import LAPTOP_ICON from "../../assets/Laptop2.png";
import CHECKLIST_ICON from "../../assets/ListChecks.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import handleBuy from "../Buy/handleBuy";
import { ITEM_TEST_PACKAGE } from "../../constants/text_constants";
import { useSelector } from "react-redux";

const TestDescriptionBanner = ({ testHeaders, isPurchased = "0" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8)), url('${BACKGROUND_IMAGE}')`,
        backgroundSize: "cover",
        fontFamily: "DM Sans",
        fontStyle: "normal",
      }}
      className="h-[23rem] px-[5rem] py-[3rem] text-[#130C40] flex relative -left-[1rem] w-screen"
    >
      {/* left Section */}
      <div>
        {/* Course Description */}
        <div>
          {testHeaders?.details.map((ele, i) => (
            <div className="flex flex-col gap-5" key={i}>
              {/* Star Rating and Total Ratings */}
              <div className="flex gap-3">
                <div>
                  <StarRatings
                    rating={Math.floor(ele?.rating)}
                    starRatedColor="#FCB129"
                    numberOfStars={5}
                    starDimension="1.2rem"
                  />
                </div>
                <div className="mt-[0.2rem] flex gap-2">
                  <div className="font-bold">{ele?.rating}</div>
                  <div className="font-light italic">({ele?.total_rating})</div>
                </div>
              </div>
              {/* Course Name */}
              <div className="font-bold text-5xl">{ele?.name}</div>
              {/* Course Desc */}
              <div className="text-sm w-[40rem]">{ele?.sub_description}</div>
              {/* Course start date, end date, validity, total Hours */}
              <div className="flex gap-10">
                {/* start date */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_BLANK_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading="lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Start Date:</div>
                    <div>{ele?.starts_on}</div>
                  </div>
                </div>
                {/* end date */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_BLANK_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading="lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">End Date:</div>
                    <div>{ele?.ends_on}</div>
                  </div>
                </div>
                {/* validity */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CALENDAR_ICON}
                      alt={ele?.starts_on}
                      className="mt-1"
                      loading="lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Validity:</div>
                    <div>{ele?.validity}</div>
                  </div>
                </div>
                {/* Live Tests */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={LAPTOP_ICON}
                      alt={ele?.test_count}
                      className="mt-1"
                      loading="lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">Live Test:</div>
                    <div>{ele?.test_count}</div>
                  </div>
                </div>
                {/* DPP Tests */}
                <div className="flex gap-2 items-start">
                  {/* icon */}
                  <div>
                    <img
                      src={CHECKLIST_ICON}
                      alt={ele?.dpp_test_count}
                      className="mt-1"
                      loading="lazy"
                    />
                  </div>
                  {/* text and value */}
                  <div>
                    <div className="text-[#82808D]">DPP:</div>
                    <div>{ele?.dpp_test_count}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Syllabus */}
        <div className="mt-3">
          <div className="flex gap-2 items-start">
            <div>
              <img src={BOOK_ICON} alt="" className="mt-1" loading="lazy" />
            </div>
            <div>
              <div className="text-[#82808D]">Syllabus</div>
              <div className="flex gap-2 mt-1">
                {testHeaders?.details.map((ele, i) => (
                  <Link to={ele?.syllabus_url} target="_blank">
                    <div className="flex items-center gap-1 bg-[#5A277C] px-3 py-1 rounded-full mx-1 cursor-pointer hover:-translate-y-1 transition-all">
                      <div className="text-white font-semibold">
                        {ele?.syllabus_title}
                      </div>

                      <img
                        src={DOWNLOAD_ICON}
                        alt={ele?.syllabus_title}
                        className="bg-[#5A277C] p-1 rounded-full mx-0"
                        loading="lazy"
                      />
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Right Section */}
      <div>
        <div
          className={
            "absolute " +
            (isSideBarOpen
              ? "top-[8rem] right-[12rem]"
              : "top-[11rem] right-[7rem]")
          }
        >
          <Card className="border border-white shadow-xl">
            <CardContent
              style={{ fontFamily: "DM Sans" }}
              className="aspect-square p-0"
            >
              <div>
                {testHeaders?.details.map((ele, i) => (
                  <div
                    className="flex flex-col items-center justify-center gap-3"
                    key={i}
                  >
                    {/* Image Section */}
                    <div>
                      <img
                        src={ele?.banner_url}
                        alt={ele?.name}
                        className="w-[22rem] h-[12rem] rounded-t-lg"
                        loading="lazy"
                      />
                    </div>
                    {/* pricing section */}
                    <div className="flex items-center gap-3">
                      {/* Discount percentage */}
                      <div>
                        {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null ? (
                          <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                            {ele?.offer_discount}% off
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* offer_price */}
                      <div className="text-[#5A277C] font-bold text-xl">
                        ₹
                        {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null
                          ? ele?.offer_price
                          : ele?.price}
                      </div>
                      {/* original price */}
                      {ele?.offer_discount !== "0" &&
                        ele?.offer_discount !== null && (
                          <div className="line-through text-gray-500">
                            ₹{ele?.price}
                          </div>
                        )}
                    </div>
                    {/* Buy Now btn */}
                    {isPurchased === "0" && (
                      <div>
                        <button
                          className="bg-[#5A277C] text-white w-[18rem] py-1 rounded-xl cursor-pointer mt-5 font-semibold"
                          onClick={() =>
                            handleBuy(
                              dispatch,
                              navigate,
                              ele?.id,
                              ITEM_TEST_PACKAGE,
                              ele?.name,
                              ele?.offer_price,
                              ele?.offer_discount,
                              ele?.price,
                              ele?.principal_amount,
                              ele?.banner_url,
                              ele?.thumbnail
                            )
                          }
                        >
                          {" "}
                          Buy Now
                        </button>
                      </div>
                    )}
                    {isPurchased === "1" && (
                      <div>
                        <div className="bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[18rem] py-1 rounded-xl font-semibold text-center mt-[1rem]">
                          {" "}
                          Test Series Purchased
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TestDescriptionBanner;
