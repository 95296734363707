import React, { useEffect, useState } from "react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const SendOTPButton = ({ setShowOtp, mobileNo, setOtpToast, setHashedOTP }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sendOTP = async (mobileNo, retries = 6) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_SEND_OTP + `=${mobileNo}`,
        requestOptions
      );
      setShowOtp(response.ok);
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => sendOTP(mobileNo, retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setHashedOTP(json?.data?.otp);
    } catch (err) {
      console.error("Error sendOTP", err);
      toast(
        "If You are Not Receiving OTP on Your Device, Please close the tab and clear the cache or check your internet connection and then Try Again to Login. Thank You"
      );
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" || event.key === "Return") {
        handleClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [mobileNo]);

  function handleClick() {
    if (mobileNo.length < 10) {
      toast("Mobile Number should be of 10 digits");
    }
    if (mobileNo.length === 10) {
      // setShowOtp(true);
      setOtpToast(true);
      sendOTP(mobileNo);
    }
  }

  return (
    <div className="flex items-center justify-center my-7">
      <button
        className="px-5 py-3 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all sm:w-[27rem] w-[13rem]"
        onClick={handleClick}
      >
        <span className="drop-shadow-md">Send OTP</span>
      </button>
    </div>
  );
};

export default SendOTPButton;
