import React from "react";
import { TESTS_PAGE_TESTS_LIST_TAB_HEADINGS } from "../../constants/text_constants";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const TestsListHeader = () => {
  const allTestsCache = useSelector((store) => store.cache.allTestsCache);
  const myTestsCache = useSelector((store) => store.cache.myTestsCache);
  const authenticated = sessionStorage.getItem("authenticated") === "1";
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex lg:gap-5 gap-3 cursor-pointer lg:text-lg text-xs">
        <div
          className={
            location?.pathname === UNPROTECTED_ROUTES?.testSeriesAll
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => navigate(UNPROTECTED_ROUTES?.testSeriesAll)}
        >
          {TESTS_PAGE_TESTS_LIST_TAB_HEADINGS[0]}
        </div>
        <div
          className={
            location?.pathname === UNPROTECTED_ROUTES?.testSeriesMy
              ? "text-[#03B3C3] underline lg:underline-offset-[0.9rem] underline-offset-[0.75rem] font-semibold"
              : ""
          }
          onClick={() => navigate(UNPROTECTED_ROUTES?.testSeriesMy)}
        >
          {TESTS_PAGE_TESTS_LIST_TAB_HEADINGS[1]}
        </div>
      </div>
      {authenticated ? (
        location?.pathname === UNPROTECTED_ROUTES?.testSeriesAll ? (
          <div className="lg:text-lg text-xs">
            Total Tests : {allTestsCache[0]?.total}
          </div>
        ) : (
          <div className="lg:text-lg text-xs">
            Purchased Tests : {myTestsCache[0]?.total}
          </div>
        )
      ) : location?.pathname === UNPROTECTED_ROUTES?.testSeriesMy ? (
        <div className="lg:text-lg text-xs">Purchased Tests : 0</div>
      ) : (
        <div className="lg:text-lg text-xs">
          Total Tests : {allTestsCache[0]?.total}
        </div>
      )}
    </div>
  );
};

export default TestsListHeader;
