import React, { useState, useEffect } from "react";
import useCourseHeaders from "../../utils/use-CourseHeaders";
import { useParams } from "react-router-dom";
import DescriptionBanner from "../PageBanner/DescriptionBanner";
import TabHeadings from "./TabHeadings";
import CourseDescriptionTab from "./CourseDescriptionTab";
import LiveClassesTab from "./LiveClassesTab";
import ClassroomTab from "./ClassroomTab";
import DPPTab from "./DPPTab";
import TestSeriesTab from "./TestSeriesTab";
import AnnouncementTab from "./AnnouncementTab";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import DetailsResponsive from "../DetailResponsive/DetailsResponsive";
import { ITEM_COURSE } from "../../constants/text_constants";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const CourseDetails = () => {
  const { courseId } = useParams();
  const courseHeaders = useCourseHeaders(courseId);
  const isPurchased = courseHeaders?.details[0]?.is_purchased;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (innerWidth <= 450) {
    return (
      <DetailsResponsive
        headerDetails={courseHeaders}
        item={ITEM_COURSE}
        isPurchased={isPurchased}
        itemId={courseId}
      />
    );
  }

  return (
    <div className="mb-[1rem] overflow-hidden">
      <DescriptionBanner
        courseHeaders={courseHeaders}
        isPurchased={isPurchased}
      />
      <TabHeadings courseId={courseId} />
      <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        {location.pathname.includes(UNPROTECTED_ROUTES?.description) && (
          <CourseDescriptionTab courseId={courseId} />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.liveClass) && (
          <LiveClassesTab
            courseId={courseId}
            isPurchased={isPurchased}
            data={courseHeaders?.details?.[0]}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.classroom) && (
          <ClassroomTab
            courseId={courseId}
            isPurchased={isPurchased}
            data={courseHeaders?.details?.[0]}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.liveTests) && (
          <TestSeriesTab
            testPackageId={courseHeaders?.details[0]?.test_package_id}
            isPurchased={isPurchased}
            showMetaDescription={true}
            data={courseHeaders?.details?.[0]}
            item={ITEM_COURSE}
            dialogTitle={"Buy Course"}
            dialogText={"To access this Course please buy this Course 😊"}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.dpp) && (
          <DPPTab
            testPackageId={courseHeaders?.details[0]?.test_package_id}
            isPurchased={isPurchased}
            showSideCTA={true}
            showMetaDescription={true}
            dialogTitle={"Buy Course"}
            data={courseHeaders?.details?.[0]}
            item={ITEM_COURSE}
            dialogText={"To access this Course please buy this Course 😊"}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.announcement) && (
          <AnnouncementTab courseId={courseId} />
        )}
      </div>
    </div>
  );
};

export default CourseDetails;
