import React, { useState } from "react";
import TestsListHeader from "./TestsListHeader";
import AllTestsList from "./AllTestsList";
import MyTestsList from "./MyTestsList";
import { useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const TestsList = () => {
  const location = useLocation();

  return (
    <div>
      <TestsListHeader />
      <div className="mt-[2rem]">
        {location?.pathname === UNPROTECTED_ROUTES?.testSeriesAll && (
          <AllTestsList />
        )}
        {location?.pathname === UNPROTECTED_ROUTES?.testSeriesMy && (
          <MyTestsList />
        )}
      </div>
    </div>
  );
};

export default TestsList;
