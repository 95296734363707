export const LP_FAQ = [
    {
        ques : "How to Buy Course, Test, or Book from Website?",
        ans : "To Purchase any of the Course, Test, or Book, watch this Video for Clear Instruction: ",
        hasLink : true,
        link : "https://youtu.be/DY3G5SHo358"
    },
    {
        ques : "How to Watch Lecture from Website?",
        ans : "Watch this Video to Understand the Use of Complete Website: ",
        hasLink : true,
        link : "https://youtu.be/F1-lGNutruI"
    },
    {
        ques : "How to enrolled in this course?",
        ans : "Click on explore option and then click on buy section and complete the payment, after the completion of payment you got automatically enrolled in this course, now click on classroom section & start your preparation.",
        hasLink : false,
        link : ""
    },
    {
        ques : "How many days will be validity of this course?",
        ans : "For Validity Details, Please Check the Course Description (Validity Section)",
        hasLink : false,
        link : ""
    },
    {
        ques : "What is syllabus of this course?",
        ans : "Pdf of syllabus available in syllabus section In Description of the Courses, kindly check it.",
        hasLink : false,
        link : "",
    },
    {
        ques : "Class will be Live or recorded?",
        ans : "Yes, there will be Both Live and Recorded Classes Available",
        hasLink : false,
        link : ""
    },
    {
        ques : "What do if we miss the scheduled class?",
        ans : "Kindly see the start and end date of course in course description.",
        hasLink : false,
        link : "",
    },
    {
        ques : "How can contact RanKplus team?",
        ans : "You can contact us on what’s App +91 8235067766, or send mail - ",
        hasLink : true,
        link : "mailto:rankplusofficial@gmail.com"
    },
    {
        ques : "Can we see video of this course in computer or Laptop?",
        ans : "Oh! Yes, you can see video Lectures in computer or Laptop.",
        hasLink : false,
        link : ""
    },
    {
        ques : "What do if we miss the scheduled class?",
        ans : "You can watch the all-previous lecture on the App/Website in recorded form, after finishing of class the all-recorded video lectures have been upload, you can watch it any time.",
        hasLink : false,
        link : ""
    }
]