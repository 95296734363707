import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGE_LIMIT, ITEM_BOOK } from "../../constants/text_constants";
import { setAllBooksCache } from "../../configs/cache";
import CourseCard from "../Card-Component/CourseCard";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import IntegratedPagination from "../Pagination/IntegratedPagination";

const AllBooksList = () => {
  const [allBooksData, setAllBooksData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const cache = useSelector((store) => store.cache.allBooksCache);
  const navigate = useNavigate();

  const fetchAllBooks = async (retries = 6) => {
    try {
      let response;
      const userAuthenticated = sessionStorage.getItem("authenticated") === "1";
      if (userAuthenticated) {
        const authToken = sessionStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_BOOKS_DATA + currentPage,
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_ALL_BOOKS_DATA + currentPage
        );
      }
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchAllBooks(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAllBooksData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      dispatch(setAllBooksCache([json?.data, currentPage]));
      if (json?.data?.token) {
        sessionStorage.setItem("token", json?.data?.token);
      }
    } catch (err) {
      console.error("Error fetchAllBooks", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setAllBooksData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchAllBooks();
    }
  }, [currentPage, cache]);

  if (!allBooksData || allBooksData?.books?.length === 0) {
    return <InValidLottie text={"No Books as of Now"} />;
  }
  return (
    <div>
      <div className="grid md:grid-cols-2 grid-cols-1 gap-[2rem]">
        {allBooksData?.books.map((ele, i) => (
          <CourseCard
            key={i}
            name={ele?.name}
            offer_price={ele?.offer_price}
            offer_discount={ele?.offer_discount}
            price={ele?.price}
            course_banner={ele?.thumbnail}
            title_1={"Edition"}
            value_1={ele?.edition}
            title_4={"Validity"}
            value_4={ele?.validity}
            rating={ele?.rating}
            faculty_name={ele?.faculty_name}
            thumbnail={ele?.thumbnail}
            description={ele?.description}
            totalRating={ele?.total_rating}
            id={ele?.id}
            exploreMoreLink={UNPROTECTED_ROUTES?.bookDetails}
            showPrice={true}
            showBuyNow={false}
            showExploreMore={true}
            showView={false}
            item={ITEM_BOOK}
            principal_amount={ele?.principal_amount}
            dimensions = {"h-[10rem] w-[40%]"}
          />
        ))}
      </div>
      <div className="mt-[5rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default AllBooksList;
