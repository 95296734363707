import React from "react";
import { HandCoins } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";

const ReferralCode = ({ referralInput, setReferralInput }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyReferralCode = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_VERIFY_REFERRAL_CODE +
          `${referralInput}`,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => verifyReferralCode(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      if (json?.status) {
        toast(`${json?.message} 🎉🥳`);
      }
    } catch (err) {
      console.error("Error verifyReferralCode", err);
    }
  };

  function handleClick() {
    verifyReferralCode();
  }

  return (
    <div className="flex justify-between items-center border-b-2 border-[#5A277C] pb-2 md:w-full w-[24rem]">
      <div className="flex gap-5 items-center">
        <div className="font-semibold text-[#5A277C] flex items-center gap-2">
          <div>
            <HandCoins />
          </div>
          <div>Have a Referral Code (optional)</div>
        </div>
        <div>
          <input
            type="text"
            placeholder="Enter Referral Code"
            value={referralInput}
            onChange={(e) => setReferralInput(e.target.value)}
            className="outline-none pb-1 max-w-[10rem] text-[#5A277C]"
          />
        </div>
      </div>
      <div>
        <button
          className="border-2 border-[#5A277C] px-2 py-1 rounded-lg text-[#5A277C] hover:bg-[#5A277C] hover:text-white transition-all"
          onClick={handleClick}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default ReferralCode;
