import React, { useEffect, useRef, useState } from "react";
import {
  CryptoJSAesJson,
  decryptUrl,
  modExp,
} from "../../utils/util-functions/cryptoUtils";
import useClassroomVideoDataPubKey from "../../utils/use-classroomVideoDataPubKey";
import OnlyPlayer from "./OnlyPlayer";
import { ShimmerUtil } from "../../utils/util-functions/shimmerUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";

const CustomVideoPlayer = ({ data, setNoteUrl }) => {
  const videoData = useClassroomVideoDataPubKey(data.id);
  const [videoUrl, setVideoUrl] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkAuthentication = async () => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_TOKEN_VALIDATION,
        requestOptions
      );
      const json = await response.json();
      // auth token is invalid
      if (json?.status === false) {
        userLogout(navigate, dispatch);
      }
    } catch (err) {}
  };

  useEffect(() => {
    let intervalId = setInterval(() => {
      checkAuthentication();
    }, 60*1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (videoData !== undefined && videoData !== null) {
      setVideoUrl(
        decryptUrl(
          videoData.videoData.details.publicKey,
          videoData.cryptoData.clientPrivateKey,
          videoData.cryptoData.prime,
          videoData.videoData.details.lecture[0].url
        )
      );

      if (videoData?.videoData?.details?.lecture?.[0]?.note_url) {
        setNoteUrl(
          decryptUrl(
            videoData.videoData.details.publicKey,
            videoData.cryptoData.clientPrivateKey,
            videoData.cryptoData.prime,
            videoData.videoData.details.lecture[0].note_url
          )
        );
      }
    }
  }, [videoData]);

  return videoUrl ? (
    <OnlyPlayer videoUrl={videoUrl} />
  ) : (
    <ShimmerUtil
      width="100%"
      height="auto"
      className="max-w-[54rem] max-h-[30rem] relative w-full pt-[56.25%] bg-gray-300 rounded-3xl player"
      borderRadius="1.5rem"
    />
  );
};

export default CustomVideoPlayer;
