import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useTestPackageHeaders from "../../utils/use-TestPackageHeaders";
import TestDescriptionBanner from "../PageBanner/TestDescriptionBanner";
import TabHeadings from "./TabHeadings";
import DescriptionTab from "./DescriptionTab";
import SolutionTab from "./SolutionTab";
import TestAnnouncementTab from "./TestAnnouncementTab";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import TestSeriesTab from "../CourseDetail/TestSeriesTab";
import DPPTab from "../CourseDetail/DPPTab";
import DetailsResponsive from "../DetailResponsive/DetailsResponsive";
import { ITEM_TEST_PACKAGE } from "../../constants/text_constants";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const TestDetails = () => {
  const { testId } = useParams();
  const testPackageHeaders = useTestPackageHeaders(testId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);
  const isPurchased = testPackageHeaders?.details[0]?.is_purchased;

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (innerWidth <= 450) {
    return (
      <DetailsResponsive
        headerDetails={testPackageHeaders}
        item={ITEM_TEST_PACKAGE}
        isPurchased={isPurchased}
        itemId={testId}
      />
    );
  }

  // console.log("testPackageHeaders", testPackageHeaders);

  return (
    <div className="-mb-[5rem] overflow-hidden">
      <TestDescriptionBanner
        testHeaders={testPackageHeaders}
        isPurchased={isPurchased}
      />
      <TabHeadings testId={testId} />
      <div
        className="mx-[5rem] my-[2rem] w-[52rem]"
        style={{ fontFamily: "DM Sans" }}
      >
        {location.pathname.includes(UNPROTECTED_ROUTES?.description) && (
          <DescriptionTab testId={testId} />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.liveTest) && (
          <TestSeriesTab
            isPurchased={isPurchased}
            testPackageId={testId}
            showMetaDescription={true}
            data={testPackageHeaders?.details?.[0]}
            item = {ITEM_TEST_PACKAGE}
            dialogTitle={"Buy Test Series"}
            dialogText = {"To access this test please buy this test series 😊"}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.dpp) && (
          <DPPTab
            isPurchased={isPurchased}
            testPackageId={testId}
            showMetaDescription={true}
            data={testPackageHeaders?.details?.[0]}
            item = {ITEM_TEST_PACKAGE}
            dialogTitle={"Buy Test Series"}
            dialogText = {"To access this test please buy this test series 😊"}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.solution) && (
          <SolutionTab
            isPurchased={isPurchased}
            testPackageId={testId}
            testData={testPackageHeaders?.details?.[0]}
          />
        )}
        {location.pathname.includes(UNPROTECTED_ROUTES?.announcement) && (
          <TestAnnouncementTab testId={testId} />
        )}
      </div>
    </div>
  );
};

export default TestDetails;
