import React from "react";
import { ScrollArea, ScrollBar } from "../../components/ui/scroll-area";
import {
  ITEM_COURSE,
  ITEM_TEST_PACKAGE,
  ITEM_BOOK,
  COURSE_DETAILS_TAB_HEADINGS,
  TEST_DETAILS_TAB_HEADINGS,
} from "../../constants/text_constants";

const BOOK_DETAILS_TABS = ["Description"];

const HorizontalScrollTab = ({ item, setTabHeading, tabHeading }) => {
  return (
    <div>
      <ScrollArea className="whitespace-nowrap">
        <div className="flex w-max space-x-4 p-2">
          {item === ITEM_COURSE &&
            COURSE_DETAILS_TAB_HEADINGS.map((ele, i) => (
              <div
                key={i}
                className={`px-4 py-2 rounded-2xl ${
                  tabHeading === ele?.title
                    ? "bg-[#5A277C] text-white"
                    : "bg-white text-black border border-[#5A277C]"
                }`}
                onClick={() => setTabHeading(ele?.title)}
              >
                {ele?.title}
              </div>
            ))}
          {item === ITEM_TEST_PACKAGE &&
            TEST_DETAILS_TAB_HEADINGS.map((ele, i) => (
              <div
                key={i}
                className={`px-4 py-2 rounded-2xl ${
                  tabHeading === ele?.title
                    ? "bg-[#5A277C] text-white"
                    : "bg-white text-black border border-[#5A277C]"
                }`}
                onClick={() => setTabHeading(ele?.title)}
              >
                {ele?.title}
              </div>
            ))}
          {/* {item === ITEM_BOOK &&
            BOOK_DETAILS_TABS.map((ele, i) => (
              <div
                key={i}
                className={`px-4 py-2 rounded-2xl ${
                  tabHeading === ele
                    ? "bg-[#5A277C] text-white"
                    : "bg-white text-black border border-[#5A277C]"
                }`}
                onClick={() => setTabHeading(ele)}
              >
                {ele}
              </div>
            ))} */}
        </div>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </div>
  );
};

export default HorizontalScrollTab;
