import React from "react";
import { toast } from "sonner";
import { useDispatch } from "react-redux";
import {
  setName,
  setEmail,
  setUserCountry,
  setUserState,
  setUserCity,
  setUserPincode,
  setUserAddress,
} from "../../configs/userSlice";
import { useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";
import {
  USER_PROFILE_UPDATED,
  USER_PROFILE_EMAIL_EXISTS_ERROR,
} from "../../constants/text_constants";
import userLogout from "../../utils/userLogout";

function isGmail(email) {
  const gmailRegex = /^[a-zA-Z0-9._%+-]+@gmail\.com$/;
  return gmailRegex.test(email);
}

const checkIfFormIsFull = (
  userName,
  userEmail,
  userAddress,
  country,
  city,
  state,
  pincode
) => {
  if (userName === "") {
    toast("Enter your name");
    return;
  }
  if (userEmail === "") {
    alert("Enter your email");
    return;
  }
  if (!isGmail(userEmail)) {
    alert("Enter your gmail id");
    return;
  }
  if (userAddress === "") {
    alert("Enter your address");
    return;
  }
  if (country === null || country === undefined) {
    alert("Select Country");
    return;
  }
  if (state === null || state === undefined) {
    alert("Select State");
    return;
  }
  if (city === null || city === undefined) {
    alert("Select City");
    return;
  }
  if (pincode === "") {
    alert("Enter your pincode");
    return;
  }
};

const submitUserDetails = async (userData, navigate, dispatch, retries = 6) => {
  try {
    const formData = new FormData();
    formData.append("name", userData?.userName);
    formData.append("address", userData?.userAddress);
    formData.append("zipcode", userData?.pincode);
    formData.append("email", userData?.userEmail);
    formData.append("country_id", userData?.country?.id);
    formData.append("state_id", userData?.state?.id);
    formData.append("city_id", userData?.city?.id);
    const authToken = sessionStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: formData,
    };

    const response = await fetch(
      process.env.REACT_APP_RANK_PLUS_EDIT_PROFILE,
      requestOptions
    );
    if (!response.ok) {
      if (response.status >= 400 && response.status < 500) {
        userLogout(navigate, dispatch, true);
      }
      if (response.status >= 500 && response.status < 600 && retries > 0) {
        console.warn(`Retrying... attempts left: ${retries}`);
        setTimeout(() => submitUserDetails(userData, navigate, dispatch, retries - 1), 10000);
        return;
      } else {
        throw new Error(`HTTP error: ${response.status}`);
      }
    }
    const json = await response.json();

    if (json?.data?.error === USER_PROFILE_EMAIL_EXISTS_ERROR) {
      toast(json?.data?.error);
    }

    if (json?.message === USER_PROFILE_UPDATED) {
      sessionStorage.setItem("userDetail", JSON.stringify(userData));
      sessionStorage.setItem("token", json?.data?.token);
      navigate(UNPROTECTED_ROUTES?.home);
      toast("Profile Updated Successfully ✅");
    }
  } catch (err) {
    console.error("Error submitUserDetails", err);
  }
};

const SubmitButton = ({
  userName,
  userEmail,
  userAddress,
  country,
  state,
  city,
  pincode,
  btnText,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleClick() {
    checkIfFormIsFull(
      userName,
      userEmail,
      userAddress,
      country,
      city,
      state,
      pincode
    );

    dispatch(setName(userName));
    dispatch(setEmail(userEmail));
    dispatch(setUserCountry(country?.name));
    dispatch(setUserState(state?.name));
    dispatch(setUserCity(city?.name));
    dispatch(setUserAddress(userAddress));
    dispatch(setUserPincode(pincode));

    // make an API call
    const userData = {
      userName,
      userEmail,
      userAddress,
      country,
      state,
      city,
      pincode,
    };
    submitUserDetails(userData, navigate, dispatch);
  }
  return (
    <div className="flex items-center justify-center my-7 lg:ml-[5rem] -ml-[2rem]">
      <button
        className="px-5 py-3 text-l font-bold text-white bg-gradient-to-r from-cyan-400 to-purple-600 rounded-full shadow-lg hover:-translate-y-1 hover:shadow-2xl transition-all lg:w-[27rem] md:w-[20rem] w-[15rem] lg:ml-0 md:ml-[10rem] ml-0"
        onClick={() => handleClick()}
      >
        <span className="drop-shadow-md">{btnText}</span>
      </button>
    </div>
  );
};

export default SubmitButton;
