import React, { useState } from "react";
import Courses from "./Courses";
import { Separator } from "../../../components/ui/separator";
import Tests from "./Tests";
import Books from "./Books";
import Library from "./Library";
import Home from "./Home";
import { ScrollArea } from "../../../components/ui/scroll-area";

const UnCollapsedSidebar = () => {
  const [showAllCourses, setShowAllCourses] = useState(false);
  const [showMyCourses, setShowMyCourses] = useState(false);
  const [showAllTests, setShowAllTests] = useState(false);
  const [showMyTests, setShowMyTests] = useState(false);
  const [showAllBooks, setShowAllBooks] = useState(false);
  const [showMyBooks, setShowMyBooks] = useState(false);

  return (
    <div
      className="sticky top-[6rem] w-[10rem] mt-2 h-screen lg:block md:hidden hidden mx-[0.5rem] text-[0.9rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <ScrollArea className="w-[100%] h-[44rem] pr-2">
        <div className="flex flex-col">
          <Home />
          <Separator className="my-[0.4rem]" />
          <Courses
            showAllCourses={showAllCourses}
            showMyCourses={showMyCourses}
            setShowAllCourses={setShowAllCourses}
            setShowMyCourses={setShowMyCourses}
            setShowAllTests={setShowAllTests}
            setShowMyTests={setShowMyTests}
            setShowAllBooks={setShowAllBooks}
            setShowMyBooks={setShowMyBooks}
          />
          <Separator className="my-[0.4rem]" />
          <Tests
            showAllTests={showAllTests}
            showMyTests={showMyTests}
            setShowAllTests={setShowAllTests}
            setShowMyTests={setShowMyTests}
            setShowMyCourses={setShowMyCourses}
            setShowAllCourses={setShowAllCourses}
            setShowAllBooks={setShowAllBooks}
            setShowMyBooks={setShowMyBooks}
          />
          <Separator className="my-[0.4rem]" />
          <Books
            showAllBooks={showAllBooks}
            showMyBooks={showMyBooks}
            setShowAllBooks={setShowAllBooks}
            setShowMyBooks={setShowMyBooks}
            setShowMyTests={setShowMyTests}
            setShowAllTests={setShowAllTests}
            setShowMyCourses={setShowMyCourses}
            setShowAllCourses={setShowAllCourses}
          />
          <Separator className="my-[0.4rem]" />
          <Library />
        </div>
      </ScrollArea>
    </div>
  );
};

export default UnCollapsedSidebar;
