import React, { useState, useEffect } from "react";
import { toast } from "sonner";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { ScrollArea } from "../../components/ui/scroll-area";

const PayNow = ({
  price,
  promoAmount,
  cartData,
  includeWallet,
  includePromo,
  suggestedBooks,
  promoInput,
  referralInput,
  gst,
}) => {
  const cart = useSelector((store) => store.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createOrder = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_CREATE_ORDER +
          `${(
            price +
            (includeWallet ? -parseFloat(cartData?.wallet_balance) : 0) +
            (includePromo ? -parseFloat(promoAmount) : 0)
          ).toFixed(2)}`,
        requestOptions
      );
      // const response = await fetch(
      //   process.env.REACT_APP_RANK_PLUS_CREATE_ORDER + `${1}`,
      //   requestOptions
      // );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => createOrder(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();

      if (json?.success) {
        toast(json?.message);

        const totalSectionDivision = {
          [cart?.name]: cart?.sellingPrice,
          gst_percentage: `${gst * 100}`,
          gst_rate: cart?.price - cart?.sellingPrice,
          offer_discount: includePromo
            ? -parseFloat(promoAmount).toFixed(2)
            : 0,
        };
        const cartbodyData = {
          item_type: cart?.item,
          item_id: cart?.id,
          suggested_books: JSON.stringify(suggestedBooks),
          total_section_division: encodeURIComponent(
            JSON.stringify(totalSectionDivision)
          ),
          used_wallet: includeWallet
            ? -parseFloat(cartData?.wallet_balance).toFixed(2)
            : 0,
          offer_code: promoInput,
          referral_code: referralInput,
          grand_total: cart?.price,
          transaction_id: json?.data?.merchantTransactionId,
          transaction_amount: `${(
            price +
            (includeWallet ? -parseFloat(cartData?.wallet_balance) : 0) +
            (includePromo ? -parseFloat(promoAmount) : 0)
          ).toFixed(2)}`,
          test_package_id: "",
        };
        sessionStorage.setItem(
          "transaction_id",
          json?.data?.merchantTransactionId
        );
        sessionStorage.setItem(
          json?.data?.merchantTransactionId,
          JSON.stringify(cartbodyData)
        );

        window.open(
          json?.data?.instrumentResponse?.redirectInfo?.url,
          "_blank"
        );
      } else {
        toast(
          "Some Problem has occured for the payment. Please Try After some time"
        );
      }
    } catch (err) {
      console.error("Error createOrder", err);
    }
  };

  function handleClick() {
    createOrder();
  }

  return (
    <div className="flex justify-between items-center md:w-full w-[24rem]">
      <div className="text-white">h</div>
      <div>
        <button
          className="border-2 border-[#5A277C] px-4 py-1 rounded-2xl font-semibold hover:bg-[#5A277C] hover:text-white transition-all hover:-translate-y-1 shadow-md hover:shadow-lg"
          onClick={() => handleClick()}
        >
          {" "}
          Pay
        </button>
      </div>
    </div>
  );
};

export default PayNow;
