import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { UNPROTECTED_ROUTES } from "./routes";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useSelector((store) => store.user.authenticated);
  return isAuthenticated || sessionStorage.getItem("authenticated") === "1" ? (
    element
  ) : (
    <Navigate to={UNPROTECTED_ROUTES?.home} />
  );
};

export default ProtectedRoute;
