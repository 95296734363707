import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";


const useWalletPoints = () => {
  const [walletHistory, setWalletHistory] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchWalletPoints = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_WALLET_HISTORY,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchWalletPoints(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setWalletHistory(json?.data);
    } catch (err) {
      console.error("Error fetchWalletPoints", err);
    }
  };

  useEffect(() => {
    fetchWalletPoints();
  }, []);

  return walletHistory;
};

export default useWalletPoints;
