import React from "react";
import FooterTop from "./FooterTop";
import FooterBottom from "./FooterBottom";

const Footer = () => {
  return (
    <div className="mt-[2rem]">
      <div className = "relative top-[13rem]">
        <FooterTop />
      </div>
      <div>
        <FooterBottom />
      </div>
    </div>
  );
};

export default Footer;
