import React from "react";
import CALENDAR_ICON from "../../../assets/Calendar.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ITEM_BOOK } from "../../../constants/text_constants";
import handleBuy from "../../Buy/handleBuy";
import BOOK_ICON from "../../../assets/Book.png";
import PENCIL_ICON from "../../../assets/PencilLine.png";
import { Carousel } from "react-responsive-carousel";

const DescriptionTop = ({
  banner_url,
  validity,
  offer_discount,
  offer_price,
  price,
  id,
  name,
  principal_amount,
  course_icon,
  is_purchased,
  category,
  edition,
  bookBanners,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div>
      {/* Banner Image Carousel*/}
      <div>
        <Carousel
          autoPlay
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
        >
          {bookBanners?.map((banner_url, ind) => (
            <div
              key={ind}
              style={{
                backgroundImage: `url('${banner_url}')`,
                backgroundSize: "100% 100%",
                backgroundPosition: "center",
              }}
              className="md:h-[15rem] h-[10rem] flex justify-between items-center px-[2rem] rounded-xl"
            ></div>
          ))}
        </Carousel>
      </div>
      {/* KPI Cards */}
      <div className="flex justify-center my-5">
        <div className="grid grid-cols-2 gap-y-[0.7rem] gap-x-[2rem]">
          {/* Category */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={BOOK_ICON}
                alt={category}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Category:</div>
              <div>{category}</div>
            </div>
          </div>
          {/* validity */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={CALENDAR_ICON}
                alt={validity}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Validity:</div>
              <div>{validity}</div>
            </div>
          </div>
          {/* Edition */}
          <div className="flex gap-2 items-start border border-[#5A277C] rounded-2xl px-2 py-1">
            {/* icon */}
            <div>
              <img
                src={PENCIL_ICON}
                alt={edition}
                className="mt-1"
                loading="lazy"
              />
            </div>
            {/* text and value */}
            <div>
              <div className="text-[#82808D]">Edition:</div>
              <div>{edition}</div>
            </div>
          </div>
        </div>
      </div>

      {/* Price and Buy Button */}
      <div className="flex justify-between items-center my-[1rem] mx-[1rem]">
        {/* Price */}
        <div className="flex items-center gap-1">
          {/* Discount percentage */}
          <div>
            {offer_discount !== "0" && offer_discount !== null ? (
              <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                {offer_discount}% off
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col">
            {/* original price */}
            {offer_discount !== "0" && offer_discount !== null && (
              <div className="line-through text-gray-500">₹{price}</div>
            )}
            {/* offer_price */}
            <div className="text-[#5A277C] font-bold">
              ₹
              {offer_discount !== "0" && offer_discount !== null
                ? offer_price
                : price}
            </div>
          </div>
        </div>

        {/* buttons */}
        <div className="flex gap-3">
          {/* Buy Now Button */}
          {is_purchased === "0" && (
            <div>
              <button
                className="text-[#5A277C] px-2 py-1 rounded-xl cursor-pointer"
                style={{
                  backgroundColor: "rgba(90, 39, 124, 0.10)",
                  fontWeight: 700,
                }}
                onClick={() =>
                  handleBuy(
                    dispatch,
                    navigate,
                    id,
                    ITEM_BOOK,
                    name,
                    offer_price,
                    offer_discount,
                    price,
                    principal_amount,
                    banner_url,
                    course_icon
                  )
                }
              >
                Buy Now
              </button>
            </div>
          )}
          {/* Purchased Text */}
          {is_purchased === "1" && (
            <div>
              <div className="bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[10rem] py-1 rounded-xl font-semibold text-center mt-[1rem]">
                {" "}
                Book Purchased
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DescriptionTop;
