import React, { useState, useEffect } from "react";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import StarRatings from "react-star-ratings";
import CALENDAR_ICON from "../../assets/Calendar2.png";
import BOOK_ICON from "../../assets/Book.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import PENCIL_ICON from "../../assets/PencilLine.png";
import { Link } from "react-router-dom";
import { Card, CardContent } from "../../components/ui/card";
import { stripHtml } from "../../utils/util-functions/stripHTML";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import handleBuy from "../Buy/handleBuy";
import { ITEM_BOOK } from "../../constants/text_constants";
import CryptoJS from "crypto-js";
import {
  CryptoJSAesJson,
  modExp,
} from "../../utils/util-functions/cryptoUtils";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";
import { Carousel } from "react-responsive-carousel";
import { useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { ScrollArea } from "../../components/ui/scroll-area";

const BookDescriptionBanner = ({
  bookHeaders,
  cryptoData = null,
  isPurchased = "0",
  bookBanners,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bookPdfUrl, setBookPdfUrl] = useState("");
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  useEffect(() => {
    if (
      cryptoData !== undefined &&
      cryptoData !== null &&
      bookHeaders !== undefined &&
      bookHeaders !== null &&
      bookHeaders?.details?.[0]?.book_pdf
    ) {
      const serverPublicKey = bookHeaders?.publicKey;

      const clientSharedSecret = modExp(
        serverPublicKey,
        cryptoData.clientPrivateKey,
        cryptoData.prime
      );
      const decodedUrl = JSON.parse(
        CryptoJS.AES.decrypt(
          bookHeaders?.details?.[0].book_pdf,
          clientSharedSecret.toString(),
          { format: CryptoJSAesJson }
        ).toString(CryptoJS.enc.Utf8)
      );
      setBookPdfUrl(decodedUrl);
    }
  }, [cryptoData]);

  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8)), url('${BACKGROUND_IMAGE}')`,
          backgroundSize: "cover",
          fontFamily: "DM Sans",
          fontStyle: "normal",
        }}
        className="h-[23rem] px-[5rem] py-[2rem] text-[#130C40] flex relative -left-[1rem] w-screen"
      >
        {/* left Section */}
        <div>
          {/* Course Description */}
          <div>
            {bookHeaders?.details.map((ele, i) => (
              <div className="flex flex-col gap-5" key={i}>
                {/* Star Rating and Total Ratings */}
                <div className="flex gap-3">
                  <div>
                    <StarRatings
                      rating={Math.floor(ele?.rating)}
                      starRatedColor="#FCB129"
                      numberOfStars={5}
                      starDimension="1.2rem"
                    />
                  </div>
                  <div className="mt-[0.2rem] flex gap-2">
                    <div className="font-bold">{ele?.rating}</div>
                    <div className="font-light italic">
                      ({ele?.total_rating})
                    </div>
                  </div>
                </div>
                {/* Course Name */}
                <div className="font-bold text-5xl">{ele?.name}</div>
                {/* Course Desc */}
                <div className="text-sm w-[40rem]">
                  {stripHtml(ele?.description)}
                </div>
                {/* Book Category, Validity and Edition */}
                <div className="flex gap-20">
                  {/* Category */}
                  <div className="flex gap-2 items-start">
                    {/* icon */}
                    <div>
                      <img
                        src={BOOK_ICON}
                        alt={ele?.category}
                        className="mt-1"
                        loading="lazy"
                      />
                    </div>
                    {/* text and value */}
                    <div>
                      <div className="text-[#82808D]">Category:</div>
                      <div>{ele?.category}</div>
                    </div>
                  </div>
                  {/* validity */}
                  <div className="flex gap-2 items-start">
                    {/* icon */}
                    <div>
                      <img
                        src={CALENDAR_ICON}
                        alt={ele?.validity}
                        className="mt-1"
                        loading="lazy"
                      />
                    </div>
                    {/* text and value */}
                    <div>
                      <div className="text-[#82808D]">Validity:</div>
                      <div>{ele?.validity}</div>
                    </div>
                  </div>
                  {/* Edition */}
                  <div className="flex gap-2 items-start">
                    {/* icon */}
                    <div>
                      <img
                        src={PENCIL_ICON}
                        alt={ele?.edition}
                        className="mt-1"
                        loading="lazy"
                      />
                    </div>
                    {/* text and value */}
                    <div>
                      <div className="text-[#82808D]">Edition:</div>
                      <div>{ele?.edition}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Syllabus */}
          <div className="mt-3">
            <div className="flex gap-2 items-start">
              <div>
                <img src={BOOK_ICON} alt="" className="mt-1" loading="lazy" />
              </div>
              <div>
                <div className="text-[#82808D]">Syllabus</div>
                <div className="flex gap-2 mt-1">
                  {bookHeaders?.details.map((ele, i) => (
                    <Link to={ele?.syllabus_url} target="_blank">
                      <div className="flex items-center gap-1 bg-[#5A277C] px-3 py-1 rounded-full mx-1 cursor-pointer hover:-translate-y-1 transition-all">
                        <div className="text-white font-semibold">
                          {ele?.syllabus_title}
                        </div>
                        <div>
                          <img
                            src={DOWNLOAD_ICON}
                            alt={ele?.syllabus_title}
                            className="bg-[#5A277C] p-1 rounded-full"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Right Section */}
        <div>
          <div
            className={
              "absolute " +
              (isSideBarOpen
                ? "top-[6rem] right-[12rem]"
                : "top-[8rem] right-[7rem]")
            }
          >
            <Card className="border border-white shadow-xl">
              <CardContent
                style={{ fontFamily: "DM Sans" }}
                className="p-2 w-[22rem]"
              >
                <div>
                  {bookHeaders?.details.map((ele, i) => (
                    <div
                      className="flex flex-col items-center justify-center gap-3"
                      key={i}
                    >
                      {/* Image Section */}
                      <div>
                        <Carousel
                          autoPlay
                          showStatus={false}
                          showThumbs={false}
                          infiniteLoop={true}
                        >
                          {bookBanners?.map((banner_url, ind) => (
                            <img src={banner_url} className="h-[20rem]" />
                          ))}
                        </Carousel>
                      </div>
                      {/* pricing section */}
                      <div className="flex items-center gap-3">
                        {/* Discount percentage */}
                        <div>
                          {ele?.offer_discount !== "0" &&
                          ele?.offer_discount !== null ? (
                            <div className="rounded-l-full rounded-r-full text-white bg-[#5A277C] p-1 w-10 text-xs text-center">
                              {ele?.offer_discount}% off
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* offer_price */}
                        <div className="text-[#5A277C] font-bold text-xl">
                          ₹
                          {ele?.offer_discount !== "0" &&
                          ele?.offer_discount !== null
                            ? ele?.offer_price
                            : ele?.price}
                        </div>
                        {/* original price */}
                        {ele?.offer_discount !== "0" &&
                          ele?.offer_discount !== null && (
                            <div className="line-through text-gray-500">
                              ₹{ele?.price}
                            </div>
                          )}
                      </div>
                      {/* Buy Now btn */}
                      {isPurchased === "0" && (
                        <div>
                          <button
                            className="bg-[#5A277C] text-white w-[18rem] py-1 rounded-xl cursor-pointer mt-3 font-semibold"
                            onClick={() =>
                              handleBuy(
                                dispatch,
                                navigate,
                                ele?.id,
                                ITEM_BOOK,
                                ele?.name,
                                ele?.offer_price,
                                ele?.offer_discount,
                                ele?.price,
                                ele?.principal_amount,
                                ele?.banner_url,
                                ele?.thumbnail
                              )
                            }
                          >
                            {" "}
                            Buy Now
                          </button>
                        </div>
                      )}
                      {/* Preview Book Btn */}
                      {isPurchased === "0" && (
                        <div>
                          <Dialog>
                            <DialogTrigger>
                              <div className="bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[18rem] py-1 rounded-xl cursor-pointer mt-1 font-semibold">
                                Preview Book
                              </div>
                            </DialogTrigger>
                            <DialogContent className="lg:max-w-[600px] md:max-w-[600px] max-w-[30rem] lg:w-[90vw] w-[90vw] lg:h-[90vh] md:h-[90vh] h-[140vw]">
                              <DialogHeader>
                                <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
                                  <div className="bg-gradient-to-r from-cyan-400 to-purple-600 bg-clip-text text-transparent lg:text-3xl text-xl font-bold">
                                    {bookHeaders?.details[0]?.name}
                                  </div>
                                </DialogTitle>
                                <DialogDescription>
                                  <ScrollArea className="lg:w-[85%] w-[90%] lg:h-[36rem] md:h-[30rem] h-[25rem] rounded-md lg:p-4 -p-1">
                                    {bookBanners?.map((banner_url, ind) => (
                                      <img
                                        src={banner_url}
                                        className="h-[40rem] my-2"
                                      />
                                    ))}
                                  </ScrollArea>
                                </DialogDescription>
                              </DialogHeader>
                            </DialogContent>
                          </Dialog>
                        </div>
                      )}
                      {/* View Book Button */}
                      {isPurchased === "1" && (
                        <div>
                          <PDFDialogButton
                            BtnStyle={
                              "bg-gradient-to-r from-cyan-400 to-purple-600 text-white w-[18rem] py-1 rounded-xl font-semibold text-center mt-[1rem]"
                            }
                            BtnText={"View Book"}
                            BtnTextStyle={"text-white"}
                            pdfUrl={bookPdfUrl}
                            DownloadFileName={"book"}
                            showIcon={false}
                            DownloadIcon={""}
                            DownloadIconStyle={""}
                            ShowBtntext={true}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookDescriptionBanner;
