import React from "react";
import { Card, CardContent } from "../../components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/ui/carousel";
import { cn } from "../../lib/utils";
import Autoplay from "embla-carousel-autoplay";
import { Link } from "react-router-dom";

const PromoCarousel = ({ promoData }) => {
  return (
    <div>
      <Carousel
        className={cn("lg:w-[70rem] md:w-[40rem] w-[18rem]")}
        plugins={[
          Autoplay({
            delay: 3000,
          }),
        ]}
      >
        <CarouselPrevious />
        <CarouselContent>
          {promoData?.map((ele, index) => (
            <CarouselItem
              key={index}
              className="pl-4 md:basis-1/2 lg:basis-1/3"
            >
              <div className="p-1">
                <Link to={ele?.redirect_url}>
                  <Card className="shadow-lg rounded-2xl">
                    <CardContent
                      className="flex flex-col items-center justify-around p-2"
                      style={{
                        fontFamily: "DM Sans",
                      }}
                    >
                      {/* code goes here */}
                      <div className="flex flex-col items-center">
                        <div>
                          <img
                            src={ele?.banner}
                            alt={ele?.description}
                            className="rounded-2xl"
                          />
                        </div>
                        <div className="text-center font-semibold lg:text-[1rem] text-[0.8rem] md:w-full w-[16rem] my-[1rem]">
                          {ele.description}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Link>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselNext />
      </Carousel>
    </div>
  );
};

export default PromoCarousel;
