import React from "react";
import { ChevronsLeft, ChevronsRight } from "lucide-react";

const Pagination = ({ currentPage, setCurrentPage, totalPages, position = "justify-center" }) => {
  
  return (
    <div className={"mt-5 flex items-center lg:gap-3 gap-1 " + position}>
      {currentPage > 0 ? (
        <ChevronsLeft
          className="lg:mx-2 mx-0 hover:-translate-x-1 transition-all cursor-pointer"
          onClick={() => setCurrentPage((currentPage) => currentPage - 1)}
        />
      ) : (
        <ChevronsLeft className="text-gray-300" />
      )}
      <div className = "lg:text-lg text-xs">
        Page {currentPage + 1} of {totalPages}
      </div>
      {currentPage < totalPages - 1 ? (
        <ChevronsRight
          className="mx-2 hover:translate-x-1 transition cursor-pointer"
          onClick={() => setCurrentPage((currentPage) => currentPage + 1)}
        />
      ) : (
        <ChevronsRight className="text-gray-300" />
      )}
    </div>
  );
};

export default Pagination;
