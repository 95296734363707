import { modExp, CryptoJSAesJson } from "./cryptoUtils";
import CryptoJS from "crypto-js";
import { toast } from "sonner";
import { decryptUrl } from "./cryptoUtils";

function DecodePdfUrl(data, encodedUrl, encryptionData) {
  try {
    return decryptUrl(
      data?.publicKey,
      encryptionData?.clientPrivateKey,
      encryptionData?.prime,
      encodedUrl
    );
  } catch (err) {
    console.error("Error Decoding URL", err);
  }

  // const serverPublicKey = data?.publicKey;
  // const clientSharedSecret = modExp(
  //   serverPublicKey,
  //   encryptionData?.clientPrivateKey,
  //   encryptionData?.prime
  // );
  // try {
  //   const decodedUrl = CryptoJS.AES.decrypt(
  //     encodedUrl,
  //     clientSharedSecret.toString(),
  //     {
  //       format: CryptoJSAesJson,
  //     }
  //   )
  //     .toString(CryptoJS.enc.Utf8)
  //     .replace(/['"]/g, "");

  //   return decodedUrl;
  // } catch (err) {
  //   toast("Something went wrong 😖 Please Refresh the Page again");
  // }
}

export default DecodePdfUrl;
