import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  PAYMENT_PAGE_PAYMENT_PENDING,
  PAYMENT_PAGE_PAYMENT_ERROR,
  PAYMENT_PAGE_TRANSACTION_NOT_FOUND,
  PAYMENT_PAGE_PAYMENT_SUCCESS,
} from "../../constants/text_constants";
import LoadingLottie from "../../assets/Loadin_animation.json";
import CustomLottie from "../Lottie-Components/CustomLottie";
import ErrorLottie from "../../assets/Error_animation.json";
import NotFoundLottie from "../../assets/NotFoundLottie.json";
import SuccessLottie from "../../assets/SuccessLottie.json";

const PaymentPage = () => {
  const [searchParams] = useSearchParams();
  const transactionId = searchParams.get("transaction_id");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [text, setText] = useState("");
  const cartData = JSON.parse(sessionStorage.getItem(transactionId));

  const placeOrder = async (transId, retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_PLACE_ORDER + `?item_type=${cartData?.item_type}&item_id=${cartData?.item_id}&suggested_book=&total_section_division=${cartData?.total_section_division}&used_wallet=${cartData?.used_wallet}&offer_code=${cartData?.offer_code}&referral_code=${cartData?.referral_code}&grand_total=${cartData?.grand_total}&order_status=PAID&transaction_id=${transId}&transaction_amount=${cartData?.transaction_amount}&test_package_id=0`,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => placeOrder(transId, retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
    } catch (err) {
      console.error("Error placeOrder", err);
    }
  };

  const fetchTransactionStatus = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_TRANSACTION_STATUS + transactionId,
        requestOptions
      );

      if (!response.ok) {
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchTransactionStatus(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setPaymentStatus(json?.code);
      if (json?.code === PAYMENT_PAGE_PAYMENT_PENDING && retries > 0) {
        setTimeout(() => fetchTransactionStatus(retries - 1), 10000);
        setText("Your Payment is still pending");
      }
      if (json?.code === PAYMENT_PAGE_PAYMENT_PENDING && retries === 0) {
        setText(
          "You payment is still pending. It is taking a little more time than expected. If payment has been deducted from your account and product hasn't been unlocked. Please contact rankplus support "
        );
      }
      if (json?.code === PAYMENT_PAGE_PAYMENT_ERROR) {
        setText("Payment failed. Please try after some time.");
      }
      if (json?.code === PAYMENT_PAGE_TRANSACTION_NOT_FOUND) {
        setText("The given transaction is not valid !!!");
      }
      if (json?.code === PAYMENT_PAGE_PAYMENT_SUCCESS) {
        setText("Transaction Complete !!!");
        placeOrder(json?.data?.transactionId);
      }
    } catch (err) {
      console.error("Error fetchTransactionStatus", err);
    }
  };

  useEffect(() => {  
    fetchTransactionStatus();
  }, []);

  return (
    <div className="mx-[15rem] -mb-[3rem]">
      {paymentStatus === PAYMENT_PAGE_PAYMENT_PENDING && (
        <CustomLottie animationData={LoadingLottie} text={text} />
      )}
      {paymentStatus === PAYMENT_PAGE_PAYMENT_ERROR && (
        <CustomLottie animationData={ErrorLottie} text={text} />
      )}
      {paymentStatus === PAYMENT_PAGE_TRANSACTION_NOT_FOUND && (
        <CustomLottie animationData={NotFoundLottie} text={text} />
      )}
      {paymentStatus === PAYMENT_PAGE_PAYMENT_SUCCESS && (
        <CustomLottie animationData={SuccessLottie} text={text} />
      )}
    </div>
  );
};

export default PaymentPage;
