import React, { useState, useCallback } from "react";
import "./style.css";
import { BadgeInfo, Copy, Users } from "lucide-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "sonner";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../components/ui/hover-card";

const ReferralCode = () => {
  const [referralCode, setReferralCode] = useState(
    JSON.parse(sessionStorage.getItem("userDetail"))?.referralCode
  );
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);
  return (
    <div
      className="main px-10 py-5 rounded-3xl flex items-center justify-between shadow-2xl lg:w-[50rem] md:w-[30rem] w-[21rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex items-center gap-3">
        <HoverCard>
          <HoverCardTrigger>
            <BadgeInfo className="cursor-pointer" />
          </HoverCardTrigger>
          <HoverCardContent className="w-80">
            <div className="text-2xl font-semibold flex items-center gap-4">Refer Batch & Earn <span><Users/></span> </div>
            <div>
              Share your referral code to your friends and earn exciting points
              on every purchase of your friends.
            </div>
          </HoverCardContent>
        </HoverCard>
        <div className="lg:text-4xl text-lg font-bold">Referral Code.</div>
      </div>
      <div className="lg:text-3xl font-semibold flex items-center gap-4">
        {referralCode}{" "}
        <CopyToClipboard onCopy={onCopy} text={referralCode}>
          <button
            className="lg:-mt-4 -mt-2 cursor-pointer"
            onClick={() => toast("Referral Code copied to Clipboard ✅")}
          >
            <Copy />
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ReferralCode;
