import React, { useState, useEffect } from "react";
import {
  COURSE_DETAILS_TEST_SERIES_HEADING,
  PAGE_LIMIT,
} from "../../constants/text_constants";
import InValidLottie from "../Lottie-Components/InValidLottie";
import LongCard from "../Card-Component/LongCard";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import IntegratedPagination from "../Pagination/IntegratedPagination";

const TestSeriesTab = ({
  testPackageId,
  isPurchased,
  showMetaDescription,
  data,
  item,
  dialogTitle,
  dialogText
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [testSeriesData, setTestSeriesData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";

  const fetchTestSeries = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_COURSE_DPP +
          testPackageId +
          `&limit=10&page=${currentPage}&type=LIVE`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchTestSeries(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setTestSeriesData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("fetchTestSeries error", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      fetchTestSeries();
    }
  }, [testPackageId, currentPage]);

  if (!userAuthenticated) {
    return (
      <div>
        <div className="md:text-2xl text-lg font-semibold my-6">
          {COURSE_DETAILS_TEST_SERIES_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Test Series"} />
        </div>
      </div>
    );
  }

  if (testSeriesData?.total === 0) {
    return (
      <div>
        <div className="md:text-2xl text-lg font-semibold my-6">
          {COURSE_DETAILS_TEST_SERIES_HEADING}
        </div>
        <div>
          <InValidLottie text={"No Live tests as of now !"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:text-2xl text-lg font-semibold my-3">
        {COURSE_DETAILS_TEST_SERIES_HEADING}
      </div>
      <div className="flex flex-col gap-4">
        {testSeriesData?.tests?.map((ele, i) => (
          <LongCard
            key={i}
            {...ele}
            is_package_purchased={testSeriesData?.is_package_purchased}
            showMetaDescription={showMetaDescription}
            dialogTitle={dialogTitle}
            data={data}
            dispatch={dispatch}
            navigate={navigate}
            item={item}
            dialogText = {dialogText}
          />
        ))}
      </div>
      <div className="mt-[3rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default TestSeriesTab;
