import React from "react";
import COURSES_ICON from "../../assets/COURSES_ICON.webp";
import TEST_SERIES_ICON from "../../assets/TEST_SERIES_ICON.webp";
import BOOKS_ICON from "../../assets/BOOKS_ICON.webp";
import LIBRARY_ICON from "../../assets/LIBRARY_ICON.webp";
import { Link, useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "../../routes/routes";
import COURSES_ICON_FILLED from "../../assets/COURSES_ICON_FILLED.webp";
import TEST_SERIES_ICON_FILLED from "../../assets/TEST_SERIES_ICON_FILLED.webp";
import BOOKS_ICON_FILLED from "../../assets/BOOKS_ICON_FILLED.webp";
import LIBRARY_ICON_FILLED from "../../assets/LIBRARY_ICON_FILLED.webp";
import HOME_BUTTON_ICON from "../../assets/HOME_BUTTON_ICON.webp";
import HOME_BUTTON_ICON_FILLED from "../../assets/HOME_BUTTON_ICON_FILLED.webp";

const CollapsedSIdebar = () => {
  const location = useLocation();

  return (
    <div
      className="sticky top-[6rem] w-[4rem] mt-2 h-screen lg:block md:hidden hidden mx-[0.6rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex flex-col gap-[20rem]">
        <div className="flex flex-col items-center gap-[1rem]">
          <Link to={UNPROTECTED_ROUTES?.home}>
            {location.pathname === UNPROTECTED_ROUTES?.home ? (
              <div className="flex flex-col items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 px-3 py-2 rounded-xl">
                <img
                  src={HOME_BUTTON_ICON}
                  alt="HOME_ICON_FILLED"
                  className="w-[2.3rem]"
                />
                <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold">
                  Home
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-1 px-3 py-2">
                <img
                  src={HOME_BUTTON_ICON_FILLED}
                  alt="HOME_ICON_FILLED"
                  className="w-[2.3rem]"
                />
                <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                  Home
                </p>
              </div>
            )}
          </Link>
          <Link to={UNPROTECTED_ROUTES?.coursesAll}>
            {location.pathname === UNPROTECTED_ROUTES?.courses ||
            location.pathname === UNPROTECTED_ROUTES?.coursesAll ||
            location.pathname === UNPROTECTED_ROUTES?.coursesMy ? (
              <div className="flex flex-col items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 px-1 py-3 rounded-xl">
                <img
                  src={COURSES_ICON_FILLED}
                  alt="COURSES_ICON"
                  className="w-[2.3rem]"
                />
                <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold">
                  Courses
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-1 px-1 py-3">
                <img
                  src={COURSES_ICON}
                  alt="COURSES_ICON"
                  className="w-[2.3rem]"
                />
                <p className="text-sm bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                  Courses
                </p>
              </div>
            )}
          </Link>
          <Link to={UNPROTECTED_ROUTES?.testSeriesAll}>
            {location.pathname === UNPROTECTED_ROUTES?.testSeries ||
            location.pathname === UNPROTECTED_ROUTES?.testSeriesAll ||
            location.pathname === UNPROTECTED_ROUTES?.testSeriesMy ? (
              <div className="flex flex-col items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2 rounded-xl">
                <img
                  src={TEST_SERIES_ICON_FILLED}
                  alt="TEST_SERIES_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold">
                  Test Series
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-1 p-2">
                <img
                  src={TEST_SERIES_ICON}
                  alt="TEST_SERIES_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                  Test Series
                </p>
              </div>
            )}
          </Link>
          <Link to={UNPROTECTED_ROUTES?.booksAll}>
            {location.pathname === UNPROTECTED_ROUTES?.books ||
            location.pathname === UNPROTECTED_ROUTES?.booksAll ||
            location.pathname === UNPROTECTED_ROUTES?.booksMy ? (
              <div className="flex flex-col items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2.5 rounded-xl">
                <img
                  src={BOOKS_ICON_FILLED}
                  alt="BOOKS_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold">
                  Books
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-1 p-2.5">
                <img
                  src={BOOKS_ICON}
                  alt="BOOKS_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                  Books
                </p>
              </div>
            )}
          </Link>
          <Link to={UNPROTECTED_ROUTES?.freeLibrary}>
            {location.pathname === UNPROTECTED_ROUTES?.freeLibrary ||
            location.pathname === UNPROTECTED_ROUTES?.freeLibraryNotes ||
            location.pathname === UNPROTECTED_ROUTES?.freeLibraryTests ||
            location.pathname === UNPROTECTED_ROUTES?.freeLibraryVideos ||
            location.pathname === UNPROTECTED_ROUTES?.blogs ||
            location.pathname.includes(
              UNPROTECTED_ROUTES?.freeLibraryVideosPlay
            ) ? (
              <div className="flex flex-col items-center gap-1 bg-gradient-to-r from-cyan-100 to-purple-100 p-2.5 rounded-xl">
                <img
                  src={LIBRARY_ICON_FILLED}
                  alt="LIBRARY_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90] font-bold">
                  Library
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center gap-1 p-2.5">
                <img
                  src={LIBRARY_ICON}
                  alt="LIBRARY_ICON"
                  className="w-[2rem] h-[1.9rem]"
                />
                <p className="text-sm text-center bg-clip-text text-transparent bg-gradient-to-r from-[#23B6D0] to-[#4C4C90]">
                  Library
                </p>
              </div>
            )}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CollapsedSIdebar;
