import {
  USER_LOGIN_RESPONSE,
  USER_REGISTERED_RESPONSE,
} from "../constants/text_constants";
import { UNPROTECTED_ROUTES } from "../routes/routes";
import { setUserData, setAuthentication } from "../configs/userSlice";

const userLogin = async (api, navigate, dispatch) => {
  try {
    const requestOptions = {
      method: "POST",
    };
    const response = await fetch(api, requestOptions);
    const json = await response.json();

    const userData = {
      userName: json?.data?.name,
      userEmail: json?.data?.email,
      userAddress: json?.data?.address,
      country: json?.data?.country_id,
      state: json?.data?.state_id,
      city: json?.data?.city_id,
      pincode: json?.data?.zipcode,
      userMobile: json?.data?.mobile,
      userId : json?.data?.user_id,
      referralCode : json?.data?.referral_code
    };
    sessionStorage.setItem("userDetail", JSON.stringify(userData));
    sessionStorage.setItem("token", json?.data?.token);
    sessionStorage.setItem("authenticated", "1");
    dispatch(setAuthentication());
    if (json?.message === USER_LOGIN_RESPONSE) {
      if (
        json?.data?.tempdata?.email === "" ||
        json?.data?.tempdata?.name === "" ||
        json?.data?.tempdata?.country_id === "" ||
        json?.data?.tempdata?.state_id === "" ||
        json?.data?.tempdata?.city_id === "" ||
        json?.data?.tempdata?.zipcode === ""
      ) {
        navigate(UNPROTECTED_ROUTES?.studentDetails);
      }
      dispatch(setUserData(json?.data));
      navigate(UNPROTECTED_ROUTES?.home);
    } else if (json?.message === USER_REGISTERED_RESPONSE) {
      navigate(UNPROTECTED_ROUTES?.studentDetails);
    }
  } catch (err) {
    console.error("Error userLogin", err);
  }
};

export default userLogin;
