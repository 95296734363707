import React, { useState } from "react";
import LOCK_ICON from "../../assets/Lock.png";
import LOCK_OPEN_ICON from "../../assets/LockSimpleOpen.png";
import PLAY_ICON from "../../assets/Play.png";
import { ChevronDown, ChevronUp } from "lucide-react";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import handleBuy from "../Buy/handleBuy";

const LongCard = ({
  title,
  description,
  question_count,
  duration,
  marks,
  is_package_purchased,
  showMetaDescription,
  data,
  dispatch,
  navigate,
  item, 
  dialogText,
  dialogTitle
}) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <div>
      <div
        className={
          "bg-[#F8FCFD] border border-[#DFE3EC] px-4 py-2 flex items-center justify-around " +
          (showDescription ? "rounded-t-xl" : "rounded-xl shadow-lg")
        }
        style={{ fontFamily: "DM Sans" }}
      >
        {/* Section 1 - Test name */}
        <div className="font-semibold">{title}</div>
        {/* Section 2 - Test Meta Description */}
        {showMetaDescription && (
          <div className="flex gap-5 text-sm">
            <div className="flex flex-col">
              <div className="text-[#82808D]">No. of questions</div>
              <div>{question_count}</div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#82808D]">Time</div>
              <div>{duration} min</div>
            </div>
            <div className="flex flex-col">
              <div className="text-[#82808D]">Marks</div>
              <div className="text-[#5A277C] font-semibold">{marks}</div>
            </div>
          </div>
        )}
        {/* Section 3 - Lock / Unlock button */}
        <div className="flex items-center gap-3 border-l border-[#DFE3EC] pl-6">
          {!is_package_purchased && (
            <div className="bg-[#03B3C3] p-2 rounded-full md:block hidden">
              <img src={LOCK_ICON} alt="lock-icon" loading="lazy" />
            </div>
          )}
          {is_package_purchased && (
            <div className="bg-[#03B3C3] p-2 rounded-full md:block hidden">
              <img src={LOCK_OPEN_ICON} alt="lock-icon" loading="lazy" />
            </div>
          )}

          <div className="bg-[#5A277C] p-2 rounded-full md:block hidden">
            <img src={PLAY_ICON} alt="play-icon" loading="lazy" />
          </div>
          {/* <div className="inset-0 bg-gradient-to-r from-cyan-400 to-purple-500 rounded-full blur-sm"></div> */}
          {!is_package_purchased && (
            <div>
              <Dialog>
                <DialogTrigger>
                  <div className="relative px-3 py-1 bg-white rounded-full overflow-hidden">
                    <span className="absolute inset-0 bg-gradient-to-r from-cyan-400 to-purple-500"></span>
                    <span className="absolute inset-[0.1rem] bg-white rounded-full"></span>
                    <span className="relative z-10 text-lg bg-gradient-to-r from-cyan-400 to-purple-500 inline-block text-transparent bg-clip-text">
                      Unlock
                    </span>
                  </div>
                </DialogTrigger>
                <DialogContent className="lg:max-w-[25rem] md:max-w-[20rem] max-w-[15rem] lg:h-[22vh] md:h-[40vh] h-[140vw]">
                  <DialogHeader>
                    <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
                      <div className="text-2xl text-[#5A277C] font-semibold">
                        {dialogTitle}
                      </div>
                    </DialogTitle>
                    <DialogDescription>
                      <div className="my-2">
                        {dialogText}
                      </div>
                    </DialogDescription>
                    <DialogFooter className="sm:justify-center">
                      <Button
                        type="button"
                        className="bg-[#5A277C] hover:bg-purple-700 mr-8"
                        onClick={() =>
                          handleBuy(
                            dispatch,
                            navigate,
                            data?.id,
                            item,
                            data?.name,
                            data?.offer_price,
                            data?.offer_discount,
                            data?.price,
                            data?.principal_amount,
                            (data?.banner_url || data?.course_banner),
                            (data?.thumbnail || data?.course_icon)
                          )
                        }
                      >
                        Buy Now
                      </Button>
                    </DialogFooter>
                  </DialogHeader>
                </DialogContent>
              </Dialog>
            </div>
          )}
          {is_package_purchased && (
            <button
              className="px-3 py-1 rounded-full bg-gradient-to-r from-cyan-400 to-purple-600 text-white hover:from-cyan-500 hover:to-purple-700 transition-colors duration-300"
              onClick={() =>
                toast("To access this test, Please login to the application")
              }
            >
              Start
            </button>
          )}

          <div
            onClick={() => setShowDescription(!showDescription)}
            className="cursor-pointer -mr-10"
          >
            {!showDescription ? <ChevronDown /> : <ChevronUp />}
          </div>
        </div>
      </div>
      {showDescription && (
        <div className="border-b border-l border-r border-[#DFE3EC] rounded-b-xl px-4 py-2 shadow-lg">
          <div
            className="custom-html-content space-y-1"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      )}
    </div>
  );
};

export default LongCard;
