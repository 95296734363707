import { Outlet, useLocation } from "react-router-dom";
import "./App.css";
import NavbarComponent from "./app_components/Navbar/NavbarComponent";
import { Toaster } from "./components/ui/sonner";
import Footer from "./app_components/Footer/Footer";
import { UNPROTECTED_ROUTES, PROTECTED_ROUTES } from "./routes/routes";
import { useEffect, useState } from "react";
import WhatsappFLoating from "./app_components/FloatingIcons/WhatsappFLoating";
import ScrollToTopFloating from "./app_components/FloatingIcons/ScrollToTopFloating";
import { useSelector } from "react-redux";
import CollapsedSIdebar from "./app_components/Sidebar/CollapsedSIdebar";
import UnCollapsedSidebar from "./app_components/Sidebar/UnCollapsedSidebar/UnCollapsedSidebar";

function App() {
  const location = useLocation();
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const isSideBarOpen = useSelector((store) => store.sidebar.isSideBarOpen);

  useEffect(() => {
    // Disable right-click on the whole document
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    // Disable specific key combinations
    const handleKeyDown = (event) => {
      if (
        event.keyCode === 123 || // F12
        (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I (Windows)
        (event.metaKey && event.altKey && event.keyCode === 73) || // Cmd+Option+I (Mac)
        (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J (Windows)
        (event.metaKey && event.altKey && event.keyCode === 74) || // Cmd+Option+J (Mac)
        (event.ctrlKey && event.keyCode === 85) || // Ctrl+U (Windows)
        (event.metaKey && event.keyCode === 85) // Cmd+U (Mac)
      ) {
        event.preventDefault();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (window.history && "scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    if (
      !location.pathname.includes(
        UNPROTECTED_ROUTES?.lecture
      ) &&
      !location.pathname.includes(UNPROTECTED_ROUTES?.notes)
    ) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="App">
      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && (
          <NavbarComponent />
        )}
      <div className="grid grid-flow-col">
        {location.pathname !== UNPROTECTED_ROUTES?.login &&
          location.pathname !== UNPROTECTED_ROUTES?.studentDetails &&
          (isSideBarOpen ? <UnCollapsedSidebar /> : <CollapsedSIdebar />)}
        <Outlet />
      </div>

      <Toaster />
      {!location.pathname.includes(UNPROTECTED_ROUTES?.courseDetails) &&
        !location.pathname.includes(UNPROTECTED_ROUTES?.testDetails) &&
        !location.pathname.includes(UNPROTECTED_ROUTES?.bookDetails) &&
        !location.pathname.includes(UNPROTECTED_ROUTES?.login) &&
        !location.pathname.includes(UNPROTECTED_ROUTES?.studentDetails) &&
        !location.pathname.includes(PROTECTED_ROUTES?.classroom) &&
        !location.pathname.includes(PROTECTED_ROUTES?.live) &&
        !location.pathname.includes(PROTECTED_ROUTES?.buy) &&
        !location.pathname.includes(PROTECTED_ROUTES?.payment) &&
        !location.pathname.includes(
          UNPROTECTED_ROUTES?.freeLibraryVideosPlay
        ) && <WhatsappFLoating />}
      {showScrollToTop && <ScrollToTopFloating />}

      {location.pathname !== UNPROTECTED_ROUTES?.login &&
        location.pathname !== UNPROTECTED_ROUTES?.studentDetails && <Footer />}
    </div>
  );
}

export default App;
