import React from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import BOOK_OPEN_ICON from "../../assets/BookOpenText2.png";
import { ChevronRight } from "lucide-react";

const ClassroomTabSubjects = ({
  courseSubjects,
  setSubjectId,
  defaultSubjectId,
  subjectId,
}) => {
  let selectedStyle = "bg-[#E5F7F9] text-[#03B3C3] font-bold";
  let hoverStyle = "hover:bg-[#E5F7F9] hover:text-[#03B3C3] hover:font-bold";
  return (
    <div>
      <ScrollArea className="h-[600px] w-[280px] rounded-md border pr-0">
        {courseSubjects?.subjects?.map((ele, i) => (
          <div
            key={i}
            className={
              "flex gap-3 items-center justify-between border-b border-[#DFE3EC] py-4 px-3 cursor-pointer " +
              (subjectId
                ? ele?.id === subjectId
                  ? selectedStyle
                  : hoverStyle
                : ele?.id === defaultSubjectId
                ? selectedStyle
                : hoverStyle)
            }
            onClick={() => setSubjectId(ele?.id)}
          >
            <div className="flex gap-3 items-center">
              <div className="bg-[#F8FCFD] p-2 rounded-full">
                <img
                  src={BOOK_OPEN_ICON}
                  alt="book-open-icon"
                  className="w-[1.5rem] h-[1.5rem]"
                  loading = "lazy"
                />
              </div>
              <div className="text-sm w-[10rem]">{ele?.name}</div>
            </div>
            <div>
              <ChevronRight />
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ClassroomTabSubjects;
