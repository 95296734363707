import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useCourseDescription = (courseId) => {
  const [courseDescription, setCourseDescription] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchCourseDescription = async (retries = 6) => {
    try {
      const requestOptions = {
        method: "GET",
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_DESCRIPTION.replace(
          /COURSE_ID/,
          courseId
        ),
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseDescription(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCourseDescription(json?.data);
    } catch (err) {
      console.error("Error fetchCourseDescription", err);
    }
  };

  useEffect(() => {
    fetchCourseDescription();
  }, [courseId]);

  return courseDescription;
};

export default useCourseDescription;
