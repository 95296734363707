import React, { useState, useEffect } from "react";
import VideoPlayer from "../Classroom/VideoPlayer";
import SuggestedVideos from "../Classroom/SuggestedVideos";
import { useParams } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const LibraryVideosPlay = () => {
  const { id } = useParams();

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      className="flex md:flex-row flex-col lg:m-[1.5rem] m-[1.3rem] md:gap-[2rem] gap-4 -mb-[10rem] overflow-hidden"
      style={{ fontFamily: "DM Sans" }}
    >
      {innerWidth <= 770 && (
        <div className="w-full">
          <VideoPlayer id={id} subjectid={""} showPdfBtn={false} />
        </div>
      )}
      {innerWidth > 770 && (
        <div className="w-screen">
          <VideoPlayer id={id} subjectid={""} showPdfBtn={false} />
        </div>
      )}

      <div className="mr-[1rem]">
        <SuggestedVideos
          id={id}
          subjectid={""}
          clickUrl={UNPROTECTED_ROUTES?.freeLibraryVideosPlay}
        />
      </div>
    </div>
  );
};

export default LibraryVideosPlay;
