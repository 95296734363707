import React, { useState } from "react";
import BooksListHeader from "./BooksListHeader";
import MyBooksList from "./MyBooksList";
import AllBooksList from "./AllBooksList";
import { useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const BooksList = () => {
  const location = useLocation();

  return (
    <div>
      <BooksListHeader />
      <div className="mt-[2rem]">
        {location?.pathname === UNPROTECTED_ROUTES?.booksAll && (
          <AllBooksList />
        )}
        {location?.pathname === UNPROTECTED_ROUTES?.booksMy && <MyBooksList />}
      </div>
    </div>
  );
};

export default BooksList;
