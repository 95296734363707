import React from "react";
import { Container, Button, Link } from "react-floating-action-button";
import WHATSAPP_ICON from "../../assets/whatsapp-logo-icon.png";

const WhatsappFLoating = () => {
  return (
    <div className="fixed bottom-[7rem] right-8 z-50">
      <Container>
        <Button
          tooltip="Hello Sir, I would like to Get Some Information about the Courses Available on Rankplus."
          onClick={() =>
            window.open(
              "https://wa.me/8235067766?text=Hello%2C%20I%20would%20like%20more%20information",
              "_blank"
            )
          }
          className="relative lg:-right-[6rem] lg:-bottom-[3rem] -right-[2.5rem] -bottom-[3rem]"
        >
          <img src={WHATSAPP_ICON} className="h-[3.5rem]" />
        </Button>
      </Container>
    </div>
  );
};

export default WhatsappFLoating;
