import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";
import { useDispatch } from "react-redux";

const useCourseHeaders = (courseId) => {
  const [courseHeaders, setCourseHeaders] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchCourseHeadersData = async (retries = 6) => {
    try {
      let response;
      const authenticated = sessionStorage.getItem("authenticated") === "1";
      if (authenticated) {
        const authToken = sessionStorage.getItem("token");
        const requestOptions = {
          method: "GET",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        };
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_HEADER.replace(
            /COURSE_ID/,
            courseId
          ),
          requestOptions
        );
      } else {
        response = await fetch(
          process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_HEADER.replace(
            /COURSE_ID/,
            courseId
          )
        );
      }

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseHeadersData(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setCourseHeaders(json?.data);
    } catch (err) {
      console.log("Error fetchCourseHeadersData", err);
    }
  };

  useEffect(() => {
    fetchCourseHeadersData();
  }, [courseId]);

  return courseHeaders;
};

export default useCourseHeaders;
