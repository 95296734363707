
export const UNPROTECTED_ROUTES = {
    terms : "/terms_and_conditions",
    privacy : "/privacy_policy",
    return : "/return_and_refund",
    home : "/",
    login : "/login",
    studentDetails : "/student_details",
    testSeries : "/test-series",
    testSeriesAll : "/test-series/all",
    testSeriesMy : "/test-series/my",
    courses : "/courses",
    coursesAll : "/courses/all",
    coursesMy : "/courses/my",
    books : "/books",
    booksAll : "/books/all",
    booksMy : "/books/my",
    blogs : "/blogs",
    courseDetails : "/course_details",
    testDetails : "/test_details",
    bookDetails : "/book_details",
    freeLibrary : "/library",
    freeLibraryVideos : "/library/videos",
    freeLibraryNotes : "/library/notes",
    freeLibraryTests : "/library/tests",
    freeLibraryVideosPlay : "/library/videos/play",
    feedback : "/feedback",
    description : "/description",
    liveClass : "/liveclass",
    classroom : "/classroom",
    liveTests : "/livetests",
    dpp : "/dpp",
    announcement : "/announcement",
    solution : "/solution",
    liveTest : "/livetest",
    lecture : "/lecture",
    notes : "/notes"
}

export const PROTECTED_ROUTES = {
    profile : "/profile",
    classroom : "/classroom", 
    live : "/live",
    buy : "/buy",
    offers : "/my_offers",
    notifications : "/notifications",
    payment : "/payment",
    support : "/support"
}