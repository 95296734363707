import React from "react";
import CoursesListHeader from "./CoursesListHeader";
import AllCoursesList from "./AllCoursesList";
import MyCoursesList from "./MyCoursesList";
import { useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const CoursesList = () => {
  const location = useLocation();

  return (
    <div>
      <CoursesListHeader />
      <div className="mt-[2rem]">
        {(location?.pathname === UNPROTECTED_ROUTES?.coursesAll) && <AllCoursesList />}
        {(location?.pathname === UNPROTECTED_ROUTES?.coursesMy) && <MyCoursesList />}
      </div>
    </div>
  );
};

export default CoursesList;
