import React from "react";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../components/ui/avatar";
import { UserRoundPen } from "lucide-react";
import UserIconAvatar from "../UserIcon/UserIcon";

const UserComment = ({ thumbnail, student_name, comment, user_id }) => {
  const userId = JSON.parse(sessionStorage.getItem("userDetail"))?.userId;

  return (
    <div className="gap-2 my-2 flex" style={{ fontFamily: "DM Sans" }}>
      <div className="flex items-center gap-2">
        <div>
          {user_id === userId ? (
            <UserIconAvatar userName={student_name} />
          ) : (
            <Avatar>
              <AvatarImage src={thumbnail} alt={student_name} />
              <AvatarFallback>
                <UserRoundPen />
              </AvatarFallback>
            </Avatar>
          )}
        </div>
        <div className="text-sm">
          <span className="font-semibold text-[#5A277C]">{student_name}</span>{" "}
          <span className="ml-1">{comment}</span>
        </div>
      </div>
    </div>
  );
};

export default UserComment;
