import React, { useState, useEffect } from "react";
import {
  BOOK_DETAILS_SOLUTION_HEADING,
  PAGE_LIMIT,
} from "../../constants/text_constants";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { Lock } from "lucide-react";
import PDF_ICON from "../../assets/PDF_ICON_2.png";
import DOWNLOAD_ICON from "../../assets/DownLoadIcon.png";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import { modExp } from "../../utils/util-functions/cryptoUtils";
import DecodePdfUrl from "../../utils/util-functions/DecryptionUtil";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import handleBuy from "../Buy/handleBuy";
import { ITEM_TEST_PACKAGE } from "../../constants/text_constants";

const SolutionTab = ({ isPurchased, testPackageId, testData }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [solutionsData, setSolutionsData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [encryptionData, setEncryptionData] = useState(null);
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchSolutions = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_TEST_DETAILS_SOLUTION +
          `page=${currentPage}&test_package_id=${testPackageId}` +
          `&publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSolutions(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setSolutionsData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("fetchSolutions error", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      fetchSolutions();
    }
  }, [encryptionData]);

  useEffect(() => setPubPvtKey(), [currentPage, testPackageId]);

  if (!userAuthenticated) {
    return (
      <div>
        <div className="md:text-2xl text-lg font-semibold my-6">
          {BOOK_DETAILS_SOLUTION_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Solutions"} />
        </div>
      </div>
    );
  }

  if (solutionsData?.total === 0) {
    return (
      <div>
        <div className="md:text-2xl text-lg font-semibold my-6">
          {BOOK_DETAILS_SOLUTION_HEADING}
        </div>
        <div>
          <InValidLottie text={"No Solutions as of now !"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:text-2xl text-lg font-semibold my-6">
        {BOOK_DETAILS_SOLUTION_HEADING}
      </div>
      <div>
        {solutionsData?.solutions?.map((ele, i) => (
          <div
            key={i}
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg p-4 my-4 flex items-center justify-between shadow-lg"
          >
            {/* left section */}
            <div className="flex items-center gap-2">
              <div className="bg-white md:p-2 p-1 rounded-full w-[4rem]">
                <img src={PDF_ICON} alt="pdf icon" loading="lazy" />
              </div>
              <div className="w-[17rem] font-semibold">{ele?.title}</div>
            </div>
            {/* Right Section */}
            {solutionsData?.is_package_purchased && (
              <div>
                <PDFDialogButton
                  BtnStyle={"bg-[#03B3C3] p-2 rounded-full cursor-pointer"}
                  BtnText={""}
                  BtnTextStyle={""}
                  ShowBtnText={false}
                  pdfUrl={DecodePdfUrl(
                    solutionsData,
                    ele?.attachment,
                    encryptionData
                  )}
                  DownloadFileName={ele?.title}
                  showIcon={true}
                  DownloadIcon={DOWNLOAD_ICON}
                  DownloadIconStyle=""
                />
              </div>
            )}
            {!solutionsData?.is_package_purchased && (
              <div>
                <Dialog>
                  <DialogTrigger>
                    <div className="bg-[#5A277C] p-2 rounded-full cursor-pointer">
                      <Lock className="text-white" />
                    </div>
                  </DialogTrigger>
                  <DialogContent className="lg:max-w-[25rem] md:max-w-[20rem] max-w-[15rem] lg:h-[22vh] md:h-[40vh] h-[140vw]">
                    <DialogHeader>
                      <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
                        <div className="text-2xl text-[#5A277C] font-semibold">
                          Buy Test Series
                        </div>
                      </DialogTitle>
                      <DialogDescription>
                        <div className="my-2">
                          To access this test please buy this test series 😊
                        </div>
                      </DialogDescription>
                      <DialogFooter className="sm:justify-center">
                        <Button
                          type="button"
                          className="bg-[#5A277C] hover:bg-purple-700 mr-8"
                          onClick={() =>
                            handleBuy(
                              dispatch,
                              navigate,
                              testData?.id,
                              ITEM_TEST_PACKAGE,
                              testData?.name,
                              testData?.offer_price,
                              testData?.offer_discount,
                              testData?.price,
                              testData?.principal_amount,
                              testData?.banner_url,
                              testData?.thumbnail
                            )
                          }
                        >
                          Buy Now
                        </Button>
                      </DialogFooter>
                    </DialogHeader>
                  </DialogContent>
                </Dialog>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-[3rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default SolutionTab;
