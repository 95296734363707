import React, { useState } from "react";
import { COURSE_DETAILS_CLASSROOM_HEADING } from "../../constants/text_constants";
import useCourseSubjects from "../../utils/use-CourseSubjects";
import ClassroomTabSubjects from "./ClassroomTabSubjects";
import ClassroomTabLectures from "./ClassroomTabLectures";
import ClassroomTabNotes from "./ClassroomTabNotes";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { useLocation, useNavigate } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const ClassroomTab = ({ courseId, isPurchased, data }) => {
  const courseSubjects = useCourseSubjects(courseId);
  const [subjectId, setSubjectId] = useState(null);
  const [showLecture, setShowLecture] = useState(true);
  const [searchLectureText, setSearchLectureText] = useState("");
  const [searchNotesText, setSearchNotesText] = useState("");
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";
  const location = useLocation();
  const navigate = useNavigate();

  if (!userAuthenticated) {
    return (
      <div>
        <div className="text-2xl font-semibold my-6">
          {COURSE_DETAILS_CLASSROOM_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Classroom"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="text-2xl font-semibold my-6">
        {COURSE_DETAILS_CLASSROOM_HEADING}
      </div>
      {!userAuthenticated && (
        <div>
          <InValidLottie text={"You Need to Login to view Live Classes"} />
        </div>
      )}
      <div className="bg-[#F8FCFD] border border-[#DFE3EC] px-4 py-3 rounded-t-xl font-semibold flex items-center">
        <div className="w-[290px]">Subjects</div>
        <div className="w-[520px] flex items-center justify-between">
          <div className="flex gap-5 items-center cursor-pointer">
            <div
              className={
                location.pathname.includes(UNPROTECTED_ROUTES?.lecture)
                  ? "text-[#03B3C3] underline underline-offset-[1.5rem]"
                  : ""
              }
              onClick={() => navigate(UNPROTECTED_ROUTES?.courseDetails + `/${courseId}` + UNPROTECTED_ROUTES?.classroom + UNPROTECTED_ROUTES?.lecture)}
            >
              Lecture
            </div>
            <div
              className={
                location.pathname.includes(UNPROTECTED_ROUTES?.notes)
                  ? "text-[#03B3C3] underline underline-offset-[1.5rem]"
                  : ""
              }
              onClick={() => navigate(UNPROTECTED_ROUTES?.courseDetails + `/${courseId}` + UNPROTECTED_ROUTES?.classroom + UNPROTECTED_ROUTES?.notes)}
            >
              Notes
            </div>
          </div>

          {(location.pathname.includes(UNPROTECTED_ROUTES?.lecture)) && (
            <div>
              <input
                type="text"
                placeholder="Search Lectures"
                className="py-2 pr-2 pl-3 rounded-lg bg-white border border-slate-400 w-[18rem] focus:outline-none text-sm font-semibold"
                onChange={(e) => setSearchLectureText(e.target.value)}
              />
            </div>
          )}
          {location.pathname.includes(UNPROTECTED_ROUTES?.notes) && (
            <div>
              <input
                type="text"
                placeholder="Search Notes"
                className="py-2 pr-2 pl-3 rounded-lg bg-white border border-slate-400 w-[18rem] focus:outline-none text-sm font-semibold"
                onChange={(e) => setSearchNotesText(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex gap-2 mt-2">
        <div>
          <ClassroomTabSubjects
            courseSubjects={courseSubjects}
            setSubjectId={setSubjectId}
            defaultSubjectId={courseSubjects?.subjects[0]?.id}
            subjectId={subjectId}
          />
        </div>
        <div className="">
          {(location.pathname.includes(UNPROTECTED_ROUTES?.lecture)) && (
            <ClassroomTabLectures
              subjectId={subjectId}
              defaultSubjectId={courseSubjects?.subjects[0]?.id}
              isPurchased={isPurchased}
              searchText={searchLectureText}
              data = {data}
            />
          )}
          {location.pathname.includes(UNPROTECTED_ROUTES?.notes) && (
            <ClassroomTabNotes
              subjectId={subjectId}
              defaultSubjectId={courseSubjects?.subjects[0]?.id}
              isPurchased={isPurchased}
              searchText={searchNotesText}
              data = {data}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ClassroomTab;
