import React from "react";
import { BOTTOM_FOOTER } from "../../constants/text_constants";
import FooterBottomCol1 from "./FooterBottomCol1";
import FooterBottomCol2 from "./FooterBottomCol2";
import FooterBottomCol3 from "./FooterBottomCol3";
import FooterBottomCol4 from "./FooterBottomCol4";
import FooterLegend from "./FooterLegend";
const FooterBottom = () => {
  return (
    <div className="bg-[#130C40] lg:h-[40rem] md:h-[69rem] h-[85rem] pt-[14rem]">
      <div
        className="flex justify-center items-center"
        style={{
          fontFamily: "DM Sans",
          fontStyle: "normal",
          lineHeight: "normal",
        }}
      >
        <div className="lg:grid lg:grid-cols-4 grid-cols-1 gap-10 mt-10">
          <div>
            <FooterBottomCol1 data={BOTTOM_FOOTER?.title} />
          </div>
          <div>
            <FooterBottomCol2 data={BOTTOM_FOOTER?.navigation} />
          </div>
          <div>
            <FooterBottomCol3 data={BOTTOM_FOOTER?.contact} />
          </div>
          <div>
            <FooterBottomCol4 data={BOTTOM_FOOTER?.socialMedia} />
          </div>
        </div>
      </div>
      <FooterLegend />
    </div>
  );
};

export default FooterBottom;
