import React, { useState, useEffect } from "react";
import { ScrollArea } from "../../components/ui/scroll-area";
import PDF_ICON from "../../assets/PDF_ICON_2.png";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { Lock } from "lucide-react";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import { modExp } from "../../utils/util-functions/cryptoUtils";
import DecodePdfUrl from "../../utils/util-functions/DecryptionUtil";
import PDFDialogButton from "../PDF-View-Component/PDFDialogButton";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import handleBuy from "../Buy/handleBuy";
import { ITEM_COURSE } from "../../constants/text_constants";

const ClassroomTabNotes = ({
  subjectId,
  defaultSubjectId,
  isPurchased,
  searchText,
  data,
}) => {
  const [notesData, setNotesData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [encryptionData, setEncryptionData] = useState(null);

  const setPubPvtKey = () => {
    const prime = 23;
    const base = 5;

    const clientPrivateKey = Math.floor(Math.random() * 100);
    const clientPublicKey = modExp(base, clientPrivateKey, prime);

    const cryptoData = {
      clientPrivateKey: clientPrivateKey,
      clientPublicKey: clientPublicKey,
      prime: prime,
    };

    setEncryptionData(cryptoData);
  };

  const fetchSubjectNotes = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env
          .REACT_APP_RANK_PLUS_COURSE_DETAILS_LIVE_CLASS_SUBJECT_NOTES +
          (subjectId ? subjectId : defaultSubjectId) +
          "&limit=150&page=" +
          currentPage +
          `&publicKey=${encryptionData.clientPublicKey}`,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchSubjectNotes(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setNotesData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("Error fetchSubjectNotes", err);
    }
  };

  useEffect(() => {
    fetchSubjectNotes();
  }, [encryptionData]);

  useEffect(() => setPubPvtKey(), [currentPage, subjectId, defaultSubjectId]);

  const filterNotes = (notes, searchText) => {
    if (!searchText) return notes;
    return notes.filter((note) =>
      note.title.toLowerCase().includes(searchText.toLowerCase())
    );
  };

  return (
    <div>
      <ScrollArea className="lg:h-[600px] lg:w-[535px] w-full h-full rounded-md border px-4">
        {filterNotes(notesData?.notes, searchText)?.map((ele, i) => (
          <div
            key={i}
            className="bg-[#F8FCFD] border border-[#DFE3EC] rounded-lg lg:p-4 p-2 my-3 flex items-center justify-between shadow-md "
          >
            {/* left section */}
            <div className="flex items-center gap-2">
              <div className="bg-white p-2 rounded-full w-[4rem] opacity-80">
                <img src={PDF_ICON} alt="pdf icon" loading="lazy" />
              </div>
              <div className="md:w-[17rem] w-[12rem] font-semibold">
                {ele?.title}
              </div>
            </div>
            {/* Right Section */}
            <div className="-ml-[5rem]">
              {!notesData?.is_locked && (
                <div>
                  <PDFDialogButton
                    BtnStyle={
                      "border-2 border-[#5A277C] lg:px-2 lg:py-1 px-1 py-0.3 rounded-xl hover:bg-[#5A277C]"
                    }
                    BtnText={"View"}
                    BtnTextStyle={"text-[#5A277C] hover:text-white"}
                    ShowBtnText={true}
                    pdfUrl={DecodePdfUrl(
                      notesData,
                      ele?.note_pdf,
                      encryptionData
                    )}
                    DownloadFileName={ele?.title}
                    showIcon={false}
                    DownloadIcon={""}
                    DownloadIconStyle=""
                  />
                </div>
              )}

              {notesData?.is_locked && (
                <div>
                  <Dialog>
                    <DialogTrigger>
                      <div className="bg-[#5A277C] p-2 rounded-full cursor-pointer">
                        <Lock className="text-white" />
                      </div>
                    </DialogTrigger>
                    <DialogContent className="lg:max-w-[25rem] md:max-w-[20rem] max-w-[15rem] lg:h-[22vh] md:h-[40vh] h-[140vw]">
                      <DialogHeader>
                        <DialogTitle className="lg:w-[40rem] md:w-[25rem] w-[18rem] text-2xl">
                          <div className="text-2xl text-[#5A277C] font-semibold">
                            Buy Course
                          </div>
                        </DialogTitle>
                        <DialogDescription>
                          <div className="my-2">
                            To access this course. Please purchase this course
                            😊
                          </div>
                        </DialogDescription>
                        <DialogFooter className="sm:justify-center">
                          <Button
                            type="button"
                            className="bg-[#5A277C] hover:bg-purple-700 mr-8"
                            onClick={() =>
                              handleBuy(
                                dispatch,
                                navigate,
                                data?.id,
                                ITEM_COURSE,
                                data?.name,
                                data?.offer_price,
                                data?.offer_discount,
                                data?.price,
                                data?.principal_amount,
                                data?.course_banner,
                                data?.course_icon
                              )
                            }
                          >
                            Buy Now
                          </Button>
                        </DialogFooter>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              )}
            </div>
          </div>
        ))}
      </ScrollArea>
    </div>
  );
};

export default ClassroomTabNotes;
