import React, { useState, useEffect } from "react";
import {
  COURSE_DETAILS_ANNOUNCEMENT_HEADING,
  PAGE_LIMIT,
} from "../../constants/text_constants";
import InValidLottie from "../Lottie-Components/InValidLottie";
import { useNavigate } from "react-router-dom";
import userLogout from "../../utils/userLogout";
import { useDispatch } from "react-redux";
import NotificationCard from "../Card-Component/NotificationCard";
import IntegratedPagination from "../Pagination/IntegratedPagination";

const AnnouncementTab = ({ courseId }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [announcementData, setAnnouncementData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const userAuthenticated = sessionStorage.getItem("authenticated") === "1";

  const fetchCourseAnnouncements = async (retries = 6) => {
    try {
      const authToken = sessionStorage.getItem("token");
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_COURSE_DETAILS_COURSE_ANNOUNCEMENT +
          "page=" +
          currentPage +
          "&type=course&id=" +
          courseId,
        requestOptions
      );
      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchCourseAnnouncements(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }
      const json = await response.json();
      setAnnouncementData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
    } catch (err) {
      console.error("fetchCourseAnnouncements error : ", err);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      fetchCourseAnnouncements();
    }
  }, [courseId, currentPage]);

  if (announcementData?.total === 0) {
    return (
      <div>
        <div className="lg:text-2xl text-lg font-semibold my-6">
          {COURSE_DETAILS_ANNOUNCEMENT_HEADING}
        </div>
        <div>
          <InValidLottie text={"No Announcements as of now !"} />
        </div>
      </div>
    );
  }

  if (!userAuthenticated) {
    return (
      <div>
        <div className="lg:text-2xl text-lg font-semibold my-6">
          {COURSE_DETAILS_ANNOUNCEMENT_HEADING}
        </div>
        <div>
          <InValidLottie text={"You Need to Login to view Announcements"} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="lg:text-2xl text-lg font-semibold my-6">
        {COURSE_DETAILS_ANNOUNCEMENT_HEADING}
      </div>
      <div className="grid grid-cols-1 gap-4">
        {announcementData?.notifications?.map((ele, i) => (
          <NotificationCard
            key={i}
            id={ele?.id}
            title={ele?.title}
            created_at={ele?.created_at}
            message={ele?.message}
            attachment={ele?.attachment}
            redirect_category={""}
          />
        ))}
      </div>
      <div className="mt-[3rem]">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default AnnouncementTab;
