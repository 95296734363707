import React from "react";
import { TEST_DETAILS_TAB_HEADINGS } from "../../constants/text_constants";
import { useNavigate, useLocation } from "react-router-dom";
import { UNPROTECTED_ROUTES } from "../../routes/routes";

const TabHeadings = ({ testId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div
      className="flex justify-between border-b border-gray-200 pb-2 text-[#3F3D4B] mt-8 mx-[5rem] w-[52rem]"
      style={{ fontFamily: "DM Sans" }}
    >
      <div className="flex gap-5 cursor-pointer">
        {TEST_DETAILS_TAB_HEADINGS.map((ele, i) => (
          <div
            key={i}
            className={
              location.pathname.includes(ele?.link)
                ? "text-[#03B3C3] underline underline-offset-[0.9rem] font-semibold"
                : ""
            }
            onClick={() =>
              navigate(
                UNPROTECTED_ROUTES?.testDetails + `/${testId}` + ele?.link
              )
            }
          >
            {ele?.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabHeadings;
