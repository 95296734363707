import React, { useState, useEffect } from "react";
import { PAGE_LIMIT } from "../../constants/text_constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import userLogout from "../../utils/userLogout";
import useAutoLogout from "../../utils/useAutoLogout";
import IntegratedPagination from "../Pagination/IntegratedPagination";
import LoadingLottie from "../Lottie-Components/LoadingLottie";
import StudentFeedbackCard from "../LandingPage/StudentFeedbackCard";
import BANNER_IMG from "../../assets/GirlWithLaptop.png";
import BACKGROUND_IMAGE from "../../assets/BgOverlayImage.jpeg";
import PageBanner from "../PageBanner/PageBanner";
import { setFeedbackCache } from "../../configs/cache";

const Feedback = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [feedbackData, setFeedbackData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const cache = useSelector((store) => store.cache.feedbackCache);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => userLogout(navigate, dispatch);
  useAutoLogout(handleLogout);

  const fetchFeedback = async (retries = 6) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_RANK_PLUS_FEEDBACK +
          `limit=${PAGE_LIMIT}&page=${currentPage}`
      );

      if (!response.ok) {
        if (response.status >= 400 && response.status < 500) {
          userLogout(navigate, dispatch, true);
        }
        if (response.status >= 500 && response.status < 600 && retries > 0) {
          console.warn(`Retrying... attempts left: ${retries}`);
          setTimeout(() => fetchFeedback(retries - 1), 10000);
          return;
        } else {
          throw new Error(`HTTP error: ${response.status}`);
        }
      }

      const json = await response.json();
      setFeedbackData(json?.data);
      setTotalPages(Math.ceil(json?.data?.total / PAGE_LIMIT));
      dispatch(setFeedbackCache([json?.data, currentPage]));
    } catch (err) {
      console.error("Error in fetchFeedback: ", err);
    }
  };

  useEffect(() => {
    if (cache[currentPage]) {
      setFeedbackData(cache[currentPage]);
      setTotalPages(Math.ceil(cache[currentPage]?.total / PAGE_LIMIT));
    } else {
      fetchFeedback();
    }
  }, [currentPage]);

  if (!feedbackData) {
    return (
      <div className="flex justify-center items-center w-screen">
        <LoadingLottie />
      </div>
    );
  }

  return (
    <div>
      <PageBanner
        title={"What Our Students Say !!"}
        subtitle={
          "Hear from learners who have transformed their skills and achieved their goals with us!"
        }
        rightImgSrc={BANNER_IMG}
        backgroundGradient={
          "linear-gradient(to right, rgba(254, 246, 250, 0.9) 45%, rgba(102, 269, 244, 0.8))"
        }
        backgroundImage={BACKGROUND_IMAGE}
        fontColor={"#130C40"}
      />
      <div className="grid lg:grid-cols-3 grid-cols-2 gap-[3rem] my-[2rem] mx-[10rem]">
        {feedbackData?.selected_students?.map((ele, i) => (
          <StudentFeedbackCard {...ele} key={i} />
        ))}
      </div>
      <div className="mt-[5rem] flex justify-center items-center">
        <IntegratedPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          position={"justify-center"}
        />
      </div>
    </div>
  );
};

export default Feedback;
